import {
  SHOW_EVENTS_LOADING,
  GET_EVENTS,
  EVENTS_ERROR,
  EDIT_EVENTS,
  CREATE_EVENTS_SUCCESS,
  DELETE_EVENTS,
} from '../actions/types';

const initialState = {
  eventlist: [],
  eventLoading: false,
  editEventLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_EVENTS_LOADING:
      return {
        ...state,
        eventLoading: true,
      };
    case GET_EVENTS:
      return {
        ...state,
        eventlist: payload,
        eventLoading: false,
      };
    case EDIT_EVENTS:
      return {
        ...state,
        eventlist: state.eventlist.map((event) =>
          event._id === payload._id
            ? {
                ...event,
                start: payload.start,
                end: payload.end,
              }
            : event
        ),
        editEventLoading: false,
        eventLoading: false,
      };
    case CREATE_EVENTS_SUCCESS:
      return {
        ...state,
        eventlist: [payload, ...state.eventlist],
        eventLoading: false,
      };
    case EVENTS_ERROR:
      return {
        ...state,
        error: payload,
        eventLoading: false,
      };
    case DELETE_EVENTS:
      return {
        ...state,
        eventlist: state.eventlist.filter((event) => event._id !== payload),
        eventLoading: false,
      };
    default:
      return state;
  }
}

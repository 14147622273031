import {
  SHOW_PROFILE_LOADING,
  GET_PROFILES,
  GET_PROFILES_CREW,
  PROFILE_ERROR,
  GET_PROFILE_DETAILS,
  UPDATE_PROFILE_SUCCESS,
  SHOW_EDIT_PROFILE_LOADING,
  EDIT_ONBOARD_SUCCESS,
  UPDATE_PROFILE_SIGNATURE,
  SHOW_PROFILE,
} from '../actions/types';

const initialState = {
  profilelist: [],
  profileID: null,
  profiledetails: null,
  profileDetails: null,
  profileLoading: false,
  editprofileloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PROFILE_LOADING:
      return {
        ...state,
        profileLoading: true,
      };
    case SHOW_EDIT_PROFILE_LOADING:
      return {
        ...state,
        editprofileloading: true,
      };
    case GET_PROFILES:
      return {
        ...state,
        profilelist: payload,
        profileLoading: false,
      };
    case GET_PROFILES_CREW:
      return {
        ...state,
        profilelist: payload,
        profileLoading: false,
      };
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        profiledetails: payload,
        profileLoading: false,
        editprofileloading: false,
      };
    case SHOW_PROFILE:
      return {
        ...state,
        profileDetails: payload,
        profileLoading: false,
        editprofileloading: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: [payload, ...state.profilelist],
        profileLoading: false,
      };
    case EDIT_ONBOARD_SUCCESS:
      return {
        ...state,
        profilelist: state.profilelist.map((profile) =>
          profile._id === payload._id
            ? {
                ...profile,
                onboard: payload.onboard,
              }
            : profile
        ),
      };
    // Modification start - 05.22.2023 - olain-tarcina-sc-425
    case UPDATE_PROFILE_SIGNATURE:
      return {
        ...state,
        profilelist: state.profilelist.map((profile) =>
          profile._id === payload._id
            ? {
                ...profile,
                signature: payload.signature,
              }
            : profile
        ),
        profileLoading: false,
        editprofileloading: false,
      };
    // Modification end - 05.22.2023 - olain-tarcina-sc-425
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        profileLoading: false,
        editprofileloading: false,
      };
    default:
      return state;
  }
}

import {
  GET_DECK,
  SHOW_DECK_LOADING,
  DECK_ERROR,
  GET_DECK_DETAILS,
  SHOW_EDIT_DECK_LOADING,
  EDIT_DECK,
  DELETE_DECK,
  CREATE_DECK,
} from '../actions/types';

/**
 * ympshipyard: Build interface for shipyard (new)
 * galing-173: fix loading state for add deck and add room button after deleting or adding.
 */

const initialState = {
  decklist: [],
  deckID: null,
  deckDetails: null,
  deckLoading: false,
  editDeckLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_DECK_LOADING:
      return {
        ...state,
        deckLoading: true,
      };
    case SHOW_EDIT_DECK_LOADING:
      return {
        ...state,
        editDeckLoading: true,
      };
    case GET_DECK:
      return {
        ...state,
        decklist: payload,
        deckLoading: false,
      };
    case CREATE_DECK:
      return {
        ...state,
        decklist: [payload, ...state.decklist],
        editDeckLoading: false,
      };
    case EDIT_DECK:
      return {
        ...state,
        decklist: state.decklist.map((team) =>
          team._id === payload._id
            ? {
                ...team,
                name: payload.name,
              }
            : team
        ),
        editDeckLoading: false,
      };
    case GET_DECK_DETAILS:
      return {
        ...state,
        deckDetails: payload,
      };
    case DELETE_DECK:
      return {
        ...state,
        decklist: state.decklist.filter((team) => team._id !== payload),
        editDeckLoading: false,
        // modification start - 09.12.2022 - galing-173
        deckLoading: false,
        // modification end - 09.12.2022 - galing-173
      };
    case DECK_ERROR:
      return {
        ...state,
        error: payload,
        deckLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_YMPEQUIPMENT,
  YMPEQUIPMENT_ERROR,
  SHOW_YMPEQUIPMENT_LOADING,
  GET_YMPEQUIPMENT_DETAILS,
  CREATE_YMPEQUIPMENT_SUCCESS,
  SHOW_EDIT_YMPEQUIPMENT_LOADING,
  EDIT_YMPEQUIPMENT_SUCCESS,
  DELETE_YMPEQUIPMENT,
  MIGRATE_YMPEQUIPMENT_SUCCESS,
} from '../actions/types';

const initialState = {
  ympequipmentlist: [],
  ympequipmentID: null,
  ympequipmentDetails: null,
  ympLoading: false,
  editYmpLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_YMPEQUIPMENT_LOADING:
      return {
        ...state,
        ympLoading: true,
      };
    case MIGRATE_YMPEQUIPMENT_SUCCESS:
      return {
        ...state,
        ympLoading: false,
      };
    case SHOW_EDIT_YMPEQUIPMENT_LOADING:
      return {
        ...state,
        editYmpLoading: true,
      };
    case GET_YMPEQUIPMENT:
      return {
        ...state,
        ympequipmentlist: payload,
        ympLoading: false,
      };
    case EDIT_YMPEQUIPMENT_SUCCESS:
      return {
        ...state,
        ympequipmentlist: state.ympequipmentlist.map((group) =>
          group._id === payload._id
            ? {
                ...group,
                name: payload.name,
                location: payload.location,
                modelNumber: payload.modelNumber,
                serialNumber: payload.serialNumber,
                manufacturer: payload.manufacturer,
                remarks: payload.remarks,
                critical: payload.critical,
                department: payload.department,
                hours: payload.hours,
              }
            : group
        ),
        editYmpLoading: false,
      };
    case GET_YMPEQUIPMENT_DETAILS:
      return {
        ...state,
        ympequipmentDetails: payload,
      };
    case CREATE_YMPEQUIPMENT_SUCCESS:
      return {
        ...state,
        ympequipmentlist: [payload, ...state.ympequipmentlist],
        ympLoading: false,
      };
    case YMPEQUIPMENT_ERROR:
      return {
        ...state,
        error: payload,
        ympLoading: false,
      };
    case DELETE_YMPEQUIPMENT:
      return {
        ...state,
        ympequipmentlist: state.ympequipmentlist.filter(
          (group) => group._id !== payload
        ),
        ympLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_YACHTMAKE,
  CREATE_YACHTSMAKE,
  YACHTMAKE_LOADING,
  DELETE_YACHTMAKE,
  YACHTMAKE_ERROR,
} from '../actions/types';

const initialState = {
  yachtmakelist: [],
  yachtmakeLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case YACHTMAKE_LOADING:
      return {
        ...state,
        yachtmakeLoading: true,
      };
    case GET_YACHTMAKE:
      return {
        ...state,
        yachtmakelist: payload,
        yachtmakeLoading: false,
      };
    case CREATE_YACHTSMAKE:
      return {
        ...state,
        yachtmakelist: [payload, ...state.yachtmakelist],
        yachtmakeLoading: false,
      };
    case YACHTMAKE_ERROR:
      return {
        ...state,
        error: payload,
        yachtmakeLoading: false,
      };
    case DELETE_YACHTMAKE:
      return {
        ...state,
        yachtmakelist: state.yachtmakelist.filter(
          (make) => make._id !== payload
        ),
        yachtmakeLoading: false,
      };
    default:
      return state;
  }
}

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// modification start - 15.08.2023 - great-galing-sc-470
import { Radio, Switch, Select, Button } from 'antd';
// modification end - 15.08.2023 - great-galing-sc-470
import ColorPicker from 'components/shared-components/ColorPicker';
import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_DARK,
} from 'constants/ThemeConstant';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import utils from 'utils/index';
// modification start - 10.13.2022 - loki-ribeiro-sc-238
import PropTypes from 'prop-types';
import {
  updateCurrentTheme,
  updateNavType,
  updateSideNavTheme,
  updateNavCollapsed,
  updateTopNavColor,
  updatefontSize,
  // modification start - 16.08.2023 - great-galing-sc-470
  updateResetTheme,
  // modification end - 16.08.2023 - great-galing-sc-470
} from 'actions/theme';
// modification end - 10.13.2022 - loki-ribeiro-sc-238

/**
 * loki-ribeiro-sc-238: save themes for individual users
 * loki-ribeiro-sc-241: Issues with side nav (delete)
 * maco-magsael-sc-389: Font Size Project - add font size to theme settings
 * great-galing-sc-470: Add reset button
 */

const ListOption = ({ name, selector, disabled, vertical }) => (
  <div
    className={`my-4 ${
      vertical ? '' : 'd-flex align-items-center justify-content-between'
    }`}
  >
    <div
      className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}
    >
      {name}
    </div>
    <div>{selector}</div>
  </div>
);

export const ThemeConfigurator = ({
  // modification start - 10.13.2022 - loki-ribeiro-sc-238
  theme: {
    navType,
    sideNavTheme,
    navCollapsed,
    topNavColor,
    currentTheme,
    themeDetails,
    // modification start - 04.05.2023 - maco-magsael-sc-389
    fontSize,
    // modification end - 04.05.2023 - maco-magsael-sc-389
  },
  auth: { user },
  updateCurrentTheme,
  updateNavType,
  updateSideNavTheme,
  updateNavCollapsed,
  updateTopNavColor,
  // modification start - 04.05.2023 - maco-magsael-sc-389
  updatefontSize,
  // modification end - 04.05.2023 - maco-magsael-sc-389
  // modification end - 10.13.2022 - loki-ribeiro-sc-238
  // modification start - 16.08.2023 - great-galing-sc-470
  updateResetTheme,
  // modification end - 16.08.2023 - great-galing-sc-470
}) => {
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const isCollapse = navCollapsed;

  // modification start - 10.13.2022 - loki-ribeiro-sc-238
  const themeID = themeDetails && themeDetails._id;
  const userID = user && user._id;
  // modification end - 10.13.2022 - loki-ribeiro-sc-238

  const { switcher, themes } = useThemeSwitcher();
  const { Option } = Select;

  // modification start - 10.13.2022 - loki-ribeiro-sc-238
  const toggleTheme = async (isChecked) => {
    // modification end - 10.13.2022 - loki-ribeiro-sc-238
    const changedTheme = isChecked ? 'dark' : 'light';
    // modification start - 10.13.2022 - loki-ribeiro-sc-238
    await updateCurrentTheme(changedTheme, userID, themeID);
    // modification end - 10.13.2022 - loki-ribeiro-sc-238
    switcher({ theme: themes[changedTheme] });
  };

  // modification start - 10.13.2022 - loki-ribeiro-sc-238
  const ontopNavColorClick = async (value) => {
    const { rgb } = value;
    const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
    const hex = utils.rgbaToHex(rgba);
    await updateTopNavColor(hex, userID, themeID);
  };

  const onNavTypeClick = async (value) => {
    // modification start - 10.13.2022 - loki-ribeiro-sc-241
    await updateNavCollapsed(false, userID, themeID);
    // modification end - 10.13.2022 - loki-ribeiro-sc-241
    await updateNavType(value, userID, themeID);
  };

  const onSideNavColor = async (value) => {
    await updateSideNavTheme(value, userID, themeID);
  };

  const toggleCollapsedNav = async (value) => {
    await updateNavCollapsed(value, userID, themeID);
  };
  // modification end - 10.13.2022 - loki-ribeiro-sc-238

  // modification start - 04.05.2023 - maco-magsael-sc-389
  const selectFont = async (value) => {
    await updatefontSize(value, userID, themeID);
  };
  // modification end - 04.05.2023 - maco-magsael-sc-389

  // modification start - 15.08.2023 - great-galing-sc-470
  const handleReset = async () => {
    await updateResetTheme(userID, themeID);
  };
  // modification end - 15.08.2023 - great-galing-sc-470

  return (
    <Fragment>
      <div className="mb-5">
        <h4 className="mb-3 font-weight-bold">Navigation</h4>
        {isNavTop ? (
          <ListOption
            name="Top Bar Color:"
            vertical
            selector={
              <ColorPicker
                color={topNavColor}
                colorChange={ontopNavColorClick}
              />
            }
          />
        ) : null}

        <ListOption
          name="Navigation Type:"
          selector={
            <Radio.Group
              size="small"
              onChange={(e) => onNavTypeClick(e.target.value)}
              value={navType}
            >
              <Radio.Button value={NAV_TYPE_SIDE}>Side</Radio.Button>
              <Radio.Button value={NAV_TYPE_TOP}>Top</Radio.Button>
            </Radio.Group>
          }
        />
        <ListOption
          name="Side Bar Color:"
          selector={
            <Radio.Group
              disabled={isNavTop}
              size="small"
              onChange={(e) => onSideNavColor(e.target.value)}
              value={sideNavTheme}
            >
              <Radio.Button value={SIDE_NAV_LIGHT}>Light</Radio.Button>
              <Radio.Button value={SIDE_NAV_DARK}>Dark</Radio.Button>
            </Radio.Group>
          }
          disabled={isNavTop}
        />
        <ListOption
          name="Side Bar Collapse:"
          selector={
            <Switch
              disabled={isNavTop}
              checked={isCollapse}
              onChange={() => toggleCollapsedNav(!navCollapsed)}
            />
          }
          disabled={isNavTop}
        />
        <ListOption
          name="Dark Theme:"
          selector={
            <Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
          }
        />
        {/* // modification start - 04.05.2023 - maco-magsael-sc-389 */}
        <ListOption
          name="Font Size:"
          selector={
            <Select value={fontSize} onChange={(value) => selectFont(value)}>
              <Option value="sm">Small</Option>
              <Option value="base">Normal</Option>
              <Option value="md">Large</Option>
            </Select>
          }
        />
        {/* // modification start - 04.05.2023 - maco-magsael-sc-389 */}
        {/* // modification start - 15.08.2023 - great-galing-sc-470 */}
        <ListOption
          name="Reset to default:"
          selector={
            <Button type="default" onClick={handleReset}>
              Reset
            </Button>
          }
        />
        {/* // modification end - 15.08.2023 - great-galing-sc-470 */}
      </div>
    </Fragment>
  );
};

// modification start - 10.13.2022 - loki-ribeiro-sc-238
ThemeConfigurator.propTypes = {
  updateCurrentTheme: PropTypes.func.isRequired,
  updateNavType: PropTypes.func.isRequired,
  updateSideNavTheme: PropTypes.func.isRequired,
  updateNavCollapsed: PropTypes.func.isRequired,
  updateTopNavColor: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  // modification start - 04.05.2023 - maco-magsael-sc-389
  updatefontSize: PropTypes.func.isRequired,
  // modification end - 04.05.2023 - maco-magsael-sc-389
  // modification start - 16.08.2023 - great-galing-sc-470
  updateResetTheme: PropTypes.func.isRequired,
  // modification end - 16.08.2023 - great-galing-sc-470
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  auth: state.auth,
});

const mapDispatchToProps = {
  updateCurrentTheme,
  updateNavType,
  updateSideNavTheme,
  updateNavCollapsed,
  updateTopNavColor,
  // modification start - 04.05.2023 - maco-magsael-sc-389
  updatefontSize,
  // modification end - 04.05.2023 - maco-magsael-sc-389
  // modification start - 16.08.2023 - great-galing-sc-470
  updateResetTheme,
  // modification end - 16.08.2023 - great-galing-sc-470
};
// modification end - 10.13.2022 - loki-ribeiro-sc-238

export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator);

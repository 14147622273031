import {
  GET_JOBDETAILS_HISTORY,
  HISTORY_ERROR,
  SHOW_HISTORY_LOADING,
  GET_GROUP_HISTORY,
  SHOW_GROUP_HISTORY_LOADING,
  SUBMIT_JOB_SUCCESS,
  // modification start - 09.16.2022 - magsael-184
  EDIT_JOB_HISTORY,
  // modification end - 09.16.2022 - magsael-184
  // modification start - 10.17.2022 - great-galing-sc-223
  EDIT_SOLO_HISTORY,
  // modification end - 10.17.2022 - great-galing-sc-223
  MORE_JOB_DETAILS_HISTORY,
  SEARCH_JOB_DETAILS_HISTORY,
} from '../actions/types';

/** Modifications
 *  magsael-184: Equipment History - Should be able to edit history of equipment
 *  great-galing-sc-223: Add functionality to delete histoPhoto and files from history
 *  olain-tarcina-sc-361: fix searching with infinite scroll
 * maco-magsael-sc-585: Jobs / Equipment: Editing must leave a trace.
 */

const initialState = {
  historylist: [],
  morehistorylist: [],
  searchhistorylist: [],
  grouphistorylist: [],
  historyID: null,
  historydetails: null,
  historyLoading: false,
  grouphistoryLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_HISTORY_LOADING:
      return {
        ...state,
        historyLoading: true,
      };
    case SHOW_GROUP_HISTORY_LOADING:
      return {
        ...state,
        grouphistoryLoading: true,
      };
    case GET_JOBDETAILS_HISTORY:
      return {
        ...state,
        historylist: payload,
        historyLoading: false,
      };
    case GET_GROUP_HISTORY:
      return {
        ...state,
        grouphistorylist: payload,
        grouphistoryLoading: false,
      };
    case SUBMIT_JOB_SUCCESS:
      return {
        ...state,
        historylist: [payload, ...state.historylist],
        grouphistorylist: [payload, ...state.grouphistorylist],
        historyLoading: false,
        grouphistoryLoading: false,
      };
    // modification start - 09.16.2022 - magsael-184
    case EDIT_JOB_HISTORY:
      return {
        ...state,
        grouphistorylist: state.grouphistorylist.map((history) =>
          history._id === payload._id
            ? {
                ...history,
                workHistory: payload.workHistory,
                // modification start - 10.17.2022 - great-galing-sc-223
                files: payload.files,
                histoPhoto: payload.histoPhoto,
                // modification end - 10.17.2022 - great-galing-sc-223
                // modification start - 02.12.2024 - maco-magsael-sc-585
                editHistory: payload.editHistory,
                // modification end - 02.12.2024 - maco-magsael-sc-585
              }
            : history
        ),
        historyLoading: false,
      };
    // modification end - 09.16.2022 - magsael-184

    case EDIT_SOLO_HISTORY:
      return {
        ...state,
        historylist: state.historylist.map((history) =>
          history._id === payload._id
            ? {
                ...history,
                workHistory: payload.workHistory,
                // modification start - 10.17.2022 - great-galing-sc-223
                files: payload.files,
                histoPhoto: payload.histoPhoto,
                // modification end - 10.17.2022 - great-galing-sc-223
                // modification start - 02.12.2024 - maco-magsael-sc-585
                editHistory: payload.editHistory,
                // modification end - 02.12.2024 - maco-magsael-sc-585
              }
            : history
        ),
        historyLoading: false,
      };
    case MORE_JOB_DETAILS_HISTORY:
      return {
        ...state,
        morehistorylist: state.morehistorylist.concat(payload),
        historyLoading: false,
      };
    // Modification start - 07.03.2023 - olain-tarcina-sc-361
    case SEARCH_JOB_DETAILS_HISTORY:
      return {
        ...state,
        searchhistorylist: state.searchhistorylist.concat(payload),
        historyLoading: false,
      };
    // Modification end - 08.03.2023 - olain-tarcina-sc-361
    case HISTORY_ERROR:
      return {
        ...state,
        error: payload,
        historyLoading: false,
      };
    default:
      return state;
  }
}

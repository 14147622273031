import {
  GET_CATEGORIES,
  CATEGORY_ERROR,
  SHOW_CATEGORY_LOADING,
  SHOW_EDIT_CATEGORY_LOADING,
  GET_CATEGORY_DETAILS,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
} from '../actions/types';
/**
 * maco-magsael-sc-558: Accounting - Category / subcategory
 *
 */

const initialState = {
  categorylist: [],
  categoryID: null,
  categoryDetails: null,
  categoryLoading: false,
  editCategoryLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_CATEGORY_LOADING:
      return {
        ...state,
        categoryLoading: true,
      };
    case SHOW_EDIT_CATEGORY_LOADING:
      return {
        ...state,
        editCategoryLoading: true,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categorylist: payload,
        categoryLoading: false,
      };
    case CATEGORY_ERROR:
      return {
        ...state,
        error: payload,
        categoryLoading: false,
      };
    case GET_CATEGORY_DETAILS:
      return {
        ...state,
        categoryDetails: payload,
        categoryLoading: false,
        editCategoryLoading: false,
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        categorylist: [payload, ...state.categorylist],
        editCategoryLoading: false,
        categoryLoading: false,
      };
    case EDIT_CATEGORY:
      return {
        ...state,
        categoryDetails: payload,
        // modification start - 01.10.2024 - maco-magsael-sc-558
        categorylist: state.categorylist.map((log) =>
          log._id === payload._id
            ? {
                ...log,
                name: payload.name,
                subCategory: payload.subCategory,
              }
            : log
        ),
        // modification end - 01.10.2024 - maco-magsael-sc-558
        editCategoryLoading: false,
        categoryLoading: false,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categorylist: state.categorylist.filter((log) => log._id !== payload),
        categoryLoading: false,
        // modification start - 01.10.2024 - maco-magsael-sc-558
        editCategoryLoading: false,
        // modification end - 01.10.2024 - maco-magsael-sc-558
      };
    default:
      return state;
  }
}

import {
  GET_SUBGROUP,
  SHOW_SUBGROUP_LOADING,
  SUBGROUP_ERROR,
  CREATE_MEASUREMENT_SUCCESS,
  DELETE_MEASUREMENT,
  EDIT_MEASUREMENT_LOGBOOK_SUCCESS,
} from '../actions/types';

const initialState = {
  subgrouplist: [],
  subgroupID: null,
  subgroupdetails: null,
  subgroupLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SUBGROUP_LOADING:
      return {
        ...state,
        subgroupLoading: true,
      };
    case GET_SUBGROUP:
      return {
        ...state,
        subgrouplist: payload,
        subgroupLoading: false,
      };
    case CREATE_MEASUREMENT_SUCCESS:
      return {
        ...state,
        subgrouplist: [payload, ...state.subgrouplist],
        subgroupLoading: false,
      };
    case DELETE_MEASUREMENT:
      return {
        ...state,
        subgrouplist: state.subgrouplist.filter(
          (measure) => measure._id !== payload
        ),
        subgroupLoading: false,
      };
    case EDIT_MEASUREMENT_LOGBOOK_SUCCESS:
      return {
        ...state,
        subgrouplist: state.subgrouplist.map((subgroup) =>
          subgroup._id === payload._id
            ? {
                ...subgroup,
                atSea: payload.atSea,
                atPort: payload.atPort,
              }
            : subgroup
        ),
        subgroupLoading: false,
      };
    case SUBGROUP_ERROR:
      return {
        ...state,
        error: payload,
        subgroupLoading: false,
      };
    default:
      return state;
  }
}

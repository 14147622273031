import {
  GET_SPARE_EQUIPMENT,
  SHOW_SPARE_EQUIPMENT_LOADING,
  SPARE_EQUIPMENT_ERROR,
  SHOW_EDIT_SPARE_EQUIPMENT_LOADING,
  EDIT_SPARE_EQUIPMENT,
  DELETE_SPARE_EQUIPMENT,
  CREATE_SPARE_EQUIPMENT,
  GET_SPARE_EQUIPMENT_DETAILS,
} from '../actions/types';

/**
 * ymploki-197: Build Spare parts interface (new)
 */

const initialState = {
  equiplist: [],
  equipID: null,
  equipDetails: null,
  equipLoading: false,
  editequipLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SPARE_EQUIPMENT_LOADING:
      return {
        ...state,
        equipLoading: true,
      };
    case SHOW_EDIT_SPARE_EQUIPMENT_LOADING:
      return {
        ...state,
        editequipLoading: true,
      };
    case GET_SPARE_EQUIPMENT:
      return {
        ...state,
        equiplist: payload,
        equipLoading: false,
      };
    case CREATE_SPARE_EQUIPMENT:
      return {
        ...state,
        equiplist: [payload, ...state.equiplist],
        editequipLoading: false,
      };
    case EDIT_SPARE_EQUIPMENT:
      return {
        ...state,
        equiplist: state.equiplist.map((spare) =>
          spare._id === payload._id
            ? {
                ...spare,
                equipmentName: payload.equipmentName,
                department: payload.department,
              }
            : spare
        ),
        editequipLoading: false,
      };
    case GET_SPARE_EQUIPMENT_DETAILS:
      return {
        ...state,
        equipDetails: payload,
      };
    case DELETE_SPARE_EQUIPMENT:
      return {
        ...state,
        equiplist: state.equiplist.filter((spare) => spare._id !== payload),
        editequipLoading: false,
        equipLoading: false,
      };
    case SPARE_EQUIPMENT_ERROR:
      return {
        ...state,
        error: payload,
        equipLoading: false,
      };
    default:
      return state;
  }
}

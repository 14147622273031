import {
  GET_SPARE,
  SHOW_SPARE_LOADING,
  SPARE_ERROR,
  SHOW_EDIT_SPARE_LOADING,
  EDIT_SPARE,
  DELETE_SPARE,
  CREATE_SPARE,
  GET_SPARE_DETAILS,
} from '../actions/types';

/**
 * ymploki-197: Build Spare parts interface (new)
 */

const initialState = {
  sparelist: [],
  spareID: null,
  spareDetails: null,
  spareLoading: false,
  editSpareLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SPARE_LOADING:
      return {
        ...state,
        spareLoading: true,
      };
    case SHOW_EDIT_SPARE_LOADING:
      return {
        ...state,
        editSpareLoading: true,
      };
    case GET_SPARE:
      return {
        ...state,
        sparelist: payload,
        spareLoading: false,
      };
    case CREATE_SPARE:
      return {
        ...state,
        sparelist: [payload, ...state.sparelist],
        editSpareLoading: false,
      };
    case EDIT_SPARE:
      return {
        ...state,
        sparelist: state.sparelist.map((spare) =>
          spare._id === payload._id
            ? {
                ...spare,
                designation: payload.designation,
                partNo: payload.partNo,
                department: payload.department,
                equipmentID: payload.equipmentID,
                sectionID: payload.sectionID,
                unit: payload.unit,
                fn: payload.fn,
                measurement: payload.measurement,
                quantity: payload.quantity,
                serailNo: payload.serailNo,
                standard: payload.standard,
                key: payload.key,
                description: payload.description,
              }
            : spare
        ),
        editSpareLoading: false,
      };
    case GET_SPARE_DETAILS:
      return {
        ...state,
        spareDetails: payload,
      };
    case DELETE_SPARE:
      return {
        ...state,
        sparelist: state.sparelist.filter((spare) => spare._id !== payload),
        editSpareLoading: false,
        spareLoading: false,
      };
    case SPARE_ERROR:
      return {
        ...state,
        error: payload,
        spareLoading: false,
        editSpareLoading: false,
      };
    default:
      return state;
  }
}

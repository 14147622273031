import {
  GET_INVOICE,
  INVOICE_ERROR,
  SHOW_INVOICE_LOADING,
  SHOW_EDIT_INVOICE_LOADING,
  GET_INVOICE_DETAILS,
  CREATE_INVOICE,
  EDIT_INVOICE,
  DELETE_INVOICE,
  DUPLICATE_INVOICE,
  DRAFT_INVOICE,
} from '../actions/types';

/**
 * maco-magsael-sc-551: Created for Accounting - Create Invoice generator
 * olain-tarcina-sc-588 - Accounting update
 *
 */

const initialState = {
  invoicelist: [],
  invoiceID: null,
  invoiceDetails: null,
  invoiceLoading: false,
  editInvoiceLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_INVOICE_LOADING:
      return {
        ...state,
        invoiceLoading: true,
      };
    case SHOW_EDIT_INVOICE_LOADING:
      return {
        ...state,
        editInvoiceLoading: true,
      };
    case GET_INVOICE:
      return {
        ...state,
        invoicelist: payload,
        invoiceLoading: false,
      };
    case INVOICE_ERROR:
      return {
        ...state,
        error: payload,
        invoiceLoading: false,
        editInvoiceLoading: false,
      };
    case GET_INVOICE_DETAILS:
      return {
        ...state,
        invoiceDetails: payload,
        invoiceLoading: false,
        editInvoiceLoading: false,
      };
    case CREATE_INVOICE:
      return {
        ...state,
        invoicelist: [payload, ...state.invoicelist],
        editInvoiceLoading: false,
        invoiceLoading: false,
      };
    case EDIT_INVOICE:
      return {
        ...state,
        invoicelist: state.invoicelist.map((invoice) =>
          invoice._id === payload._id
            ? {
                ...invoice,
                status: payload.status,
                title: payload.title,
                fromName: payload.fromDetails.name,
                fromEmail: payload.fromDetails.email,
                fromAddress: payload.fromDetails.address,
                fromPhone: payload.fromDetails.phone,
                toName: payload.toDetails.name,
                toEmail: payload.toDetails.email,
                toAddress: payload.toDetails.address,
                toPhone: payload.toDetails.phone,
                toMobile: payload.toDetails.mobile,
                toFax: payload.toDetails.fax,
                invoiceNumber: payload.invoiceNumber,
                date: payload.date,
                withTax: payload.tax.withTax,
                taxRate: payload.tax.rate,
                taxTotal: payload.taxTotal,
                currency: payload.currency,
                subTotal: payload.subTotal,
                total: payload.total,
                balanceDue: payload.balanceDue,
                signatureURL: payload.signatureURL,
                notes: payload.notes,
                description: payload.description,
              }
            : invoice
        ),
        editInvoiceLoading: false,
        invoiceLoading: false,
      };
    // Modification start - 01.03.2024 - olain-tarcina-sc-588
    case DRAFT_INVOICE:
      return {
        ...state,
        invoiceDetails: payload,
        invoiceLoading: false,
        editInvoiceLoading: false,
      };
    // Modification end - 01.03.2024 - olain-tarcina-sc-588
    case DELETE_INVOICE:
      return {
        ...state,
        invoicelist: state.invoicelist.filter(
          (invoice) => invoice._id !== payload
        ),
        editInvoiceLoading: false,
        invoiceLoading: false,
      };
    case DUPLICATE_INVOICE:
      return {
        ...state,
        invoicelist: payload,
        editInvoiceLoading: false,
        invoiceLoading: false,
      };

    default:
      return state;
  }
}

import {
  SHOW_PART_LOADING,
  GET_PART,
  PART_ERROR,
  EDIT_PART,
  CREATE_PART_SUCCESS,
  DELETE_PART,
} from '../actions/types';

/**
 * maco-magsael-sc-485: Created for Equipment - Add annotation for equipment
 *
 */

const initialState = {
  partlist: [],
  partLoading: false,
  editPartLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PART_LOADING:
      return {
        ...state,
        partLoading: true,
      };
    case GET_PART:
      return {
        ...state,
        partlist: payload,
        partLoading: false,
      };
    case EDIT_PART:
      return {
        ...state,
        partlist: state.partlist.map((parts) =>
          parts._id === payload._id
            ? {
                ...parts,
                start: payload.start,
                end: payload.end,
                allDay: payload.allDay,
              }
            : parts
        ),
        editPartLoading: false,
        partLoading: false,
      };
    case CREATE_PART_SUCCESS:
      return {
        ...state,
        partlist: [payload, ...state.partlist],
        partLoading: false,
      };
    case PART_ERROR:
      return {
        ...state,
        error: payload,
        partLoading: false,
      };
    case DELETE_PART:
      return {
        ...state,
        partlist: state.partlist.filter((parts) => parts._id !== payload),
        partLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_LOGRECORD,
  GET_LOGRECORD_DETAILS,
  LOGRECORD_ERROR,
  SHOW_LOGRECORD_LOADING,
  SHOW_EDIT_LOGRECORD_LOADING,
  CREATE_LOGRECORD,
  EDIT_LOGRECORD,
  DELETE_LOGRECORD,
} from '../actions/types';

/** Modifications
 * great-galing-sc-355: bug when editing / creating custom logbooks
 */

const initialState = {
  logrecordlist: [],
  logrecordID: null,
  logrecordDetails: null,
  logrecordLoading: false,
  editLogrecordLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LOGRECORD_LOADING:
      return {
        ...state,
        logrecordLoading: true,
      };
    case SHOW_EDIT_LOGRECORD_LOADING:
      return {
        ...state,
        editLogrecordLoading: true,
      };
    case GET_LOGRECORD:
      return {
        ...state,
        logrecordlist: payload,
        logrecordLoading: false,
      };
    case CREATE_LOGRECORD:
      return {
        ...state,
        logrecordlist: [payload, ...state.logrecordlist],
        editLogrecordLoading: false,
      };
    case EDIT_LOGRECORD:
      return {
        ...state,
        logrecordlist: state.logrecordlist.map((logrecord) =>
          logrecord._id === payload._id
            ? {
                ...logrecord,
                name: payload.name,
              }
            : logrecord
        ),
        editLogrecordLoading: false,
        // modification start - 03.07.2023 - great-galing-sc-355
        logrecordDetails: payload,
        // modification end - 03.07.2023 - great-galing-sc-355
      };
    case GET_LOGRECORD_DETAILS:
      return {
        ...state,
        logrecordDetails: payload,
        logrecordLoading: false,
      };
    case DELETE_LOGRECORD:
      return {
        ...state,
        logrecordlist: state.logrecordlist.filter(
          (location) => location._id !== payload
        ),
        logrecordLoading: false,
      };
    case LOGRECORD_ERROR:
      return {
        ...state,
        error: payload,
        logrecordLoading: false,
      };
    default:
      return state;
  }
}

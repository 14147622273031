/**
 * maco-magsael-sc-389: Font Size Project - add font size to theme settings
 * great-galing-sc-470: Add reset button
 */

export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SIDE_NAV_STYLE_CHANGE = 'SIDE_NAV_STYLE_CHANGE';
export const NAV_TYPE_CHANGE = 'NAV_TYPE_CHANGE';
export const TOP_NAV_COLOR_CHANGE = 'TOP_NAV_COLOR_CHANGE';
export const HEADER_NAV_COLOR_CHANGE = 'HEADER_NAV_COLOR_CHANGE';
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
export const SWITCH_THEME = 'SWITCH_THEME';
export const DIRECTION_CHANGE = 'DIRECTION_CHANGE';
// modification start - 04.05.2023 - maco-magsael-sc-389
export const FONT_SIZE = 'FONT_SIZE';
// modification end - 04.05.2023 - maco-magsael-sc-389
// modification start - 16.08.2023 - great-galing-sc-470
export const RESET_THEME = 'RESET_THEME';
// modification end - 16.08.2023 - great-galing-sc-470

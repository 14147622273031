import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
//import store from './redux/store';
import store from 'store';
import { CometChat } from '@cometchat-pro/chat';
import './App.css';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';
// modification start - 10.13.2022 - loki-ribeiro-sc-238
import ThemeSwitcher from 'components/themeswitcher/ThemeSwitcher';
// modification end - 10.13.2022 - loki-ribeiro-sc-238

/**
 * loki-ribeiro-sc-238: save themes for individual users
 */

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region = process.env.REACT_APP_COMETCHAT_REGION;
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();

CometChat.init(appID, appSetting);

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcher />
      </Provider>
    </div>
  );
};

export default App;

import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  SIDE_NAV_STYLE_CHANGE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  HEADER_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  SWITCH_THEME,
  DIRECTION_CHANGE,
  FONT_SIZE,
  // modification start - 16.08.2023 - great-galing-sc-470
  RESET_THEME,
  // modification end - 16.08.2023 - great-galing-sc-470
} from '../constants/Theme';
import { GET_THEME_DETAILS, THEME_ERROR } from '../actions/types';

/**
 * loki-ribeiro-sc-238: save themes for individual users
 * maco-magsael-sc-389: Font Size Project - add font size to theme settings
 * great-galing-sc-470: Add reset button
 */

const initialState = {
  navCollapsed: false,
  sideNavTheme: 'SIDE_NAV_LIGHT',
  locale: 'en',
  navType: 'TOP',
  topNavColor: '#2B2E33',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'dark',
  direction: 'ltr',
  themeDetails: {},
  // modification start - 04.05.2023 - maco-magsael-sc-389
  fontSize: 'base',
  // modification end - 04.05.2023 - maco-magsael-sc-389
  error: {},
};

const theme = (state = initialState, action) => {
  const { type, payload, locale, headerNavColor, mobileNav, direction } =
    action;

  switch (type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: payload.navCollapsed,
        themeDetails: payload,
      };
    case SIDE_NAV_STYLE_CHANGE:
      return {
        ...state,
        sideNavTheme: payload.sideNavTheme,
        themeDetails: payload,
      };
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: locale,
      };
    case NAV_TYPE_CHANGE:
      return {
        ...state,
        navType: payload.navType,
        themeDetails: payload,
      };
    case TOP_NAV_COLOR_CHANGE:
      return {
        ...state,
        topNavColor: payload.topNavColor,
        themeDetails: payload,
      };
    // modification start - 04.05.2023 - maco-magsael-sc-389
    case FONT_SIZE:
      return {
        ...state,
        fontSize: payload.fontSize,
        themeDetails: payload,
      };
    // modification end - 04.05.2023 - maco-magsael-sc-389
    case HEADER_NAV_COLOR_CHANGE:
      return {
        ...state,
        headerNavColor: headerNavColor,
      };
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: mobileNav,
      };
    case SWITCH_THEME:
      return {
        ...state,
        currentTheme: payload.currentTheme,
        themeDetails: payload,
      };
    case DIRECTION_CHANGE:
      return {
        ...state,
        direction: direction,
      };
    case GET_THEME_DETAILS: {
      return {
        ...state,
        navCollapsed:
          payload && payload.navCollapsed ? payload.navCollapsed : false,
        sideNavTheme:
          payload && payload.sideNavTheme
            ? payload.sideNavTheme
            : 'SIDE_NAV_LIGHT',
        locale: payload && payload.locale ? payload.locale : 'en',
        navType: payload && payload.navType ? payload.navType : 'TOP',
        topNavColor:
          payload && payload.topNavColor ? payload.topNavColor : '#2B2E33',
        headerNavColor:
          payload && payload.headerNavColor ? payload.headerNavColor : '',
        mobileNav: payload && payload.mobileNav ? payload.mobileNav : false,
        currentTheme:
          payload && payload.currentTheme ? payload.currentTheme : 'dark',
        direction: payload && payload.direction ? payload.direction : 'ltr',
        fontSize: payload && payload.fontSize ? payload.fontSize : 'base',
        themeDetails: payload,
      };
    }
    // modification start - 16.08.2023 - great-galing-sc-470
    case RESET_THEME: {
      return {
        ...state,
        navCollapsed: payload.navCollapsed,
        sideNavTheme: payload.sideNavTheme,
        navType: payload.navType,
        topNavColor: payload.topNavColor,
        fontSize: payload.fontSize,
        currentTheme: payload.currentTheme,
        themeDetails: payload,
      };
    }
    // modification end - 16.08.2023 - great-galing-sc-470
    case THEME_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    default:
      return state;
  }
};

export default theme;

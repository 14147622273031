import {
  SHOW_CALENDAR_LOADING,
  GET_CALENDAR,
  CALENDAR_ERROR,
  EDIT_CALENDAR,
  CREATE_CALENDAR_SUCCESS,
  DELETE_CALENDAR,
} from '../actions/types';

/**
 * maco-magsael-sc-458: Created for Calendars - develop calendar module
 * maco-magsael-sc-589: Calendar
 */

const initialState = {
  calendarlist: [],
  calendarLoading: false,
  editCalendarLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_CALENDAR_LOADING:
      return {
        ...state,
        calendarLoading: true,
      };
    case GET_CALENDAR:
      return {
        ...state,
        calendarlist: payload,
        calendarLoading: false,
      };
    case EDIT_CALENDAR:
      return {
        ...state,
        calendarlist: state.calendarlist.map((event) =>
          event._id === payload._id
            ? {
                ...event,
                // modification start - 02.19.2024 - maco-magsael-sc-589
                title: payload.title,
                // modification end - 02.19.2024 - maco-magsael-sc-589
                start: payload.start,
                end: payload.end,
                allDay: payload.allDay,
                // modification start - 02.19.2024 - maco-magsael-sc-589
                type: payload.type,
                department: payload.department,
                borderColor: payload.borderColor,
                // modification end - 02.19.2024 - maco-magsael-sc-589
              }
            : event
        ),
        editCalendarLoading: false,
        calendarLoading: false,
      };
    case CREATE_CALENDAR_SUCCESS:
      return {
        ...state,
        calendarlist: [payload, ...state.calendarlist],
        calendarLoading: false,
      };
    case CALENDAR_ERROR:
      return {
        ...state,
        error: payload,
        calendarLoading: false,
      };
    case DELETE_CALENDAR:
      return {
        ...state,
        calendarlist: state.calendarlist.filter(
          (event) => event._id !== payload
        ),
        calendarLoading: false,
      };
    default:
      return state;
  }
}

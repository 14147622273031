import {
  GET_EQUIPMENT,
  SHOW_EQUIPMENT_LOADING,
  EQUIPMENT_ERROR,
  SHOW_EDIT_EQUIPMENT_LOADING,
  GET_EQUIPMENT_DETAILS,
  CREATE_EQUIPMENT_SUCCESS,
  EDIT_EQUIPMENT_SUCCESS,
  EDIT_EQUIPMENT_LOGBOOK_SUCCESS,
  DELETE_EQUIPMENT,
  EDIT_CURRENT_HOURS_SUCCESS,
} from '../actions/types';

/** Modifications
    magsael-198: Equipment -Archive equipment feature
    olain-tarcina-sc-619: Jobs - add department
*/

const initialState = {
  grouplist: [],
  groupID: null,
  groupdetails: null,
  loading: false,
  editloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_EQUIPMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SHOW_EDIT_EQUIPMENT_LOADING:
      return {
        ...state,
        editloading: true,
      };
    case GET_EQUIPMENT:
      return {
        ...state,
        grouplist: payload,
        loading: false,
      };
    case EDIT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        grouplist: state.grouplist.map((group) =>
          group._id === payload._id
            ? {
                ...group,
                name: payload.name,
                location: payload.location,
                modelNumber: payload.modelNumber,
                serialNumber: payload.serialNumber,
                manufacturer: payload.manufacturer,
                remarks: payload.remarks,
                critical: payload.critical,
                hours: payload.hours,
                // modification start - 10.04.2022 - magsael-198
                isArchived: payload.isArchived,
                // modification end - 10.04.2022 - magsael-198
                // Modification start - 26.03.2024 - olain-tarcina-sc-619
                department: payload.department,
                // Modification end - 26.03.2024 - olain-tarcina-sc-619
              }
            : group
        ),
        editloading: false,
      };
    case EDIT_EQUIPMENT_LOGBOOK_SUCCESS:
      return {
        ...state,
        grouplist: state.grouplist.map((group) =>
          group._id === payload._id
            ? {
                ...group,
                atSea: payload.atSea,
                atPort: payload.atPort,
              }
            : group
        ),
        editloading: false,
      };
    case EDIT_CURRENT_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_EQUIPMENT_DETAILS:
      return {
        ...state,
        groupdetails: payload,
      };
    case CREATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        grouplist: [payload, ...state.grouplist],
        loading: false,
      };
    case EQUIPMENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_EQUIPMENT:
      return {
        ...state,
        grouplist: state.grouplist.filter((group) => group._id !== payload),
        loading: false,
      };
    default:
      return state;
  }
}

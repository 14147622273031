import {
  SHOW_WATCHKEEPERS_LOADING,
  GET_WATCHKEEPERS,
  WATCHKEEPERS_ERROR,
  EDIT_WATCHKEEPERS,
} from '../actions/types';

const initialState = {
  watchkeeperlist: [],
  watchLoading: false,
  editWatchLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_WATCHKEEPERS_LOADING:
      return {
        ...state,
        watchLoading: true,
      };
    case GET_WATCHKEEPERS:
      return {
        ...state,
        watchkeeperlist: payload,
        watchLoading: false,
      };
    case EDIT_WATCHKEEPERS:
      return {
        ...state,
        watchkeeperlist: state.watchkeeperlist.map((watchkeeper) =>
          watchkeeper._id === payload._id
            ? {
                ...watchkeeper,
                status: payload.status,
                done: payload.done,
              }
            : watchkeeper
        ),
        editWatchLoading: false,
        watchLoading: false,
      };
    case WATCHKEEPERS_ERROR:
      return {
        ...state,
        error: payload,
        watchLoading: false,
      };
    default:
      return state;
  }
}

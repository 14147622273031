import {
  SHOW_PLAN_LOADING,
  GET_PLAN,
  PLAN_ERROR,
  EDIT_PLAN,
  CREATE_PLAN_SUCCESS,
  DELETE_PLAN,
} from '../actions/types';

const initialState = {
  planlist: [],
  planLoading: false,
  editPartLoading: false,
  error: {},
};

/**
 * maco-magsael-sc-663: Created for Shipyard - add files and pinpoint feature
 *
 */

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PLAN_LOADING:
      return {
        ...state,
        planLoading: true,
      };
    case GET_PLAN:
      return {
        ...state,
        planlist: payload,
        planLoading: false,
      };
    case EDIT_PLAN:
      return {
        ...state,
        planlist: state.planlist.map((plans) =>
          plans._id === payload._id
            ? {
                ...plans,
                annotatePlans: payload.annotatePlans,
                name: payload.name,
              }
            : plans
        ),
        editPartLoading: false,
        planLoading: false,
      };
    case CREATE_PLAN_SUCCESS:
      return {
        ...state,
        planlist: [payload, ...state.planlist],
        planLoading: false,
      };
    case PLAN_ERROR:
      return {
        ...state,
        error: payload,
        planLoading: false,
      };
    case DELETE_PLAN:
      return {
        ...state,
        planlist: state.planlist.filter((plans) => plans._id !== payload),
        planLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_LOCATION,
  SHOW_LOCATION_LOADING,
  LOCATION_ERROR,
  GET_LOCATION_DETAILS,
  SHOW_EDIT_LOCATION_LOADING,
  CREATE_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION,
} from '../actions/types';

const initialState = {
  locationlist: [],
  locationID: null,
  locationdetails: null,
  locationLoading: false,
  editLocLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LOCATION_LOADING:
      return {
        ...state,
        locationLoading: true,
      };
    case SHOW_EDIT_LOCATION_LOADING:
      return {
        ...state,
        editLocLoading: true,
      };
    case GET_LOCATION:
      return {
        ...state,
        locationlist: payload,
        locationLoading: false,
      };
    case CREATE_LOCATION:
      return {
        ...state,
        locationlist: [payload, ...state.locationlist],
        editLocLoading: false,
      };
    case EDIT_LOCATION:
      return {
        ...state,
        locationlist: state.locationlist.map((LOCATION) =>
          LOCATION._id === payload._id
            ? {
                ...LOCATION,
                name: payload.name,
              }
            : LOCATION
        ),
        editLocLoading: false,
      };
    case GET_LOCATION_DETAILS:
      return {
        ...state,
        locationdetails: payload,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        locationlist: state.locationlist.filter(
          (location) => location._id !== payload
        ),
        locationLoading: false,
      };
    case LOCATION_ERROR:
      return {
        ...state,
        error: payload,
        locationLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_FORMS,
  FORMS_ERROR,
  SHOW_FORMS_LOADING,
  SHOW_EDIT_FORMS_LOADING,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_FAIL,
  GET_FORM_DETAILS,
  EDIT_FORM,
  DELETE_FORM,
  SHOW_LOADING_DONE,
} from '../actions/types';

/**
 * maco-magsael-sc-326: ISM - make form creation similar with manage logbook interface
 */

const initialState = {
  formlist: [],
  formID: null,
  formDetails: null,
  formLoading: false,
  editFormLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_FORMS_LOADING:
      return {
        ...state,
        formLoading: true,
      };
    case SHOW_EDIT_FORMS_LOADING:
      return {
        ...state,
        editFormLoading: true,
      };
    case GET_FORMS:
      return {
        ...state,
        formlist: payload,
        formLoading: false,
      };
    case CREATE_FORM_FAIL:
    case FORMS_ERROR:
      return {
        ...state,
        error: payload,
        formLoading: false,
      };
    case CREATE_FORM_SUCCESS:
      return {
        ...state,
        formlist: [payload, ...state.formlist],
        formLoading: false,
      };
    case EDIT_FORM:
      // modification start - 2.8.2023 - maco-magsael-sc-326
      return {
        ...state,
        formlist: state.formlist.map((form) =>
          form._id === payload._id
            ? {
                ...form,
                name: payload.name,
              }
            : form
        ),
        formLoading: false,
      };
    // modification end - 2.8.2023 - maco-magsael-sc-326
    case GET_FORM_DETAILS:
      return {
        ...state,
        formDetails: payload,
        formLoading: false,
      };
    case DELETE_FORM:
      return {
        ...state,
        formlist: state.formlist.filter((form) => form._id !== payload),
      };
    case SHOW_LOADING_DONE:
      return {
        ...state,
        formLoading: false,
      };
    default:
      return state;
  }
}

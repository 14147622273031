import {
  GET_SHIPYARD,
  SHIPYARD_ERROR,
  SHOW_SHIPYARD_LOADING,
  SHOW_EDIT_SHIPYARD_LOADING,
  SHOW_USER_SHIPYARD_LOADING,
  SHOW_PROGRESS_SHIPYARD_LOADING,
  GET_SHIPYARD_DETAILS,
  CREATE_SHIPYARD,
  EDIT_SHIPYARD,
  SHIPYARD_DRAFT_JOB,
  DELETE_SHIPYARD,
} from '../actions/types';

const initialState = {
  shipyardlist: [],
  shipyardID: null,
  shipyardDetails: null,
  shipyardDraftDetails: null,
  shipyardLoading: false,
  editShipyardLoading: false,
  editShipyardProgressLoading: false,
  editShipyardUserLoading: false,
  error: {},
};

/**
 * new-shipyard created
 *
 */

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SHIPYARD_LOADING:
      return {
        ...state,
        shipyardLoading: true,
      };
    case SHOW_EDIT_SHIPYARD_LOADING:
      return {
        ...state,
        editShipyardLoading: true,
      };
    case SHOW_USER_SHIPYARD_LOADING:
      return {
        ...state,
        editShipyardUserLoading: true,
      };
    case SHOW_PROGRESS_SHIPYARD_LOADING:
      return {
        ...state,
        editShipyardProgressLoading: true,
      };
    case GET_SHIPYARD:
      return {
        ...state,
        shipyardlist: payload,
        shipyardLoading: false,
      };
    case SHIPYARD_ERROR:
      return {
        ...state,
        error: payload,
        shipyardLoading: false,
        editShipyardLoading: false,
      };
    case GET_SHIPYARD_DETAILS:
      return {
        ...state,
        shipyardDetails: payload,
        shipyardDraftDetails: payload,
        shipyardLoading: false,
        editShipyardLoading: false,
        editShipyardProgressLoading: false,
        editShipyardUserLoading: false,
      };
    case SHIPYARD_DRAFT_JOB:
      return {
        ...state,
        shipyardDraftDetails: payload,
        shipyardLoading: false,
        editShipyardLoading: false,
        editShipyardProgressLoading: false,
        editShipyardUserLoading: false,
      };
    case CREATE_SHIPYARD:
      return {
        ...state,
        shipyardlist: [payload, ...state.shipyardlist],
        editShipyardLoading: false,
        shipyardLoading: false,
        editShipyardProgressLoading: false,
        editShipyardUserLoading: false,
      };
    case EDIT_SHIPYARD:
      return {
        ...state,
        shipyardlist: state.shipyardlist.map((shipyard) =>
          shipyard._id === payload._id
            ? {
                ...shipyard,
                no: payload.no,
                shipyardRef: payload.shipyardRef,
                jobName: payload.jobName,
                jobTitle: payload.jobTitle,
                priority: payload.priority,
                delegatedTo: payload.delegatedTo,
                workStatus: payload.workStatus,
                estimates: payload.estimates,
                quote1: payload.quote1,
                quote2: payload.quote2,
                quote3: payload.quote3,
                total: payload.total,
                description: payload.description,
                decks: payload.decks,
                rooms: payload.rooms,
              }
            : shipyard
        ),
        shipyardDetails: payload,
        editShipyardLoading: false,
        shipyardLoading: false,
        editShipyardProgressLoading: false,
        editShipyardUserLoading: false,
      };
    case DELETE_SHIPYARD:
      return {
        ...state,
        shipyardlist: state.shipyardlist.filter(
          (shipyard) => shipyard._id !== payload
        ),
        editShipyardLoading: false,
        shipyardLoading: false,
        editShipyardProgressLoading: false,
        editShipyardUserLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_LOGS,
  LOG_ERROR,
  SHOW_LOG_LOADING,
  SHOW_EDIT_LOG_LOADING,
  GET_LOG_DETAILS,
  CREATE_LOG,
  EDIT_LOG,
  DELETE_LOG,
  DELETE_LOG_RECORDS,
} from '../actions/types';

const initialState = {
  loglist: [],
  logID: null,
  logDetails: null,
  logLoading: false,
  editLogLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LOG_LOADING:
      return {
        ...state,
        logLoading: true,
      };
    case SHOW_EDIT_LOG_LOADING:
      return {
        ...state,
        editLogLoading: true,
      };
    case GET_LOGS:
      return {
        ...state,
        loglist: payload,
        logLoading: false,
      };
    case LOG_ERROR:
      return {
        ...state,
        error: payload,
        logLoading: false,
      };
    case GET_LOG_DETAILS:
      return {
        ...state,
        logDetails: payload,
        logLoading: false,
        editLogLoading: false,
      };
    case CREATE_LOG:
      return {
        ...state,
        loglist: [payload, ...state.loglist],
        editLogLoading: false,
        logLoading: false,
      };
    case EDIT_LOG:
      return {
        ...state,
        logDetails: payload,
        editLogLoading: false,
        logLoading: false,
      };
    case DELETE_LOG:
      return {
        ...state,
        loglist: state.loglist.filter((log) => log._id !== payload),
        logLoading: false,
        editLogLoading: false,
      };
    case DELETE_LOG_RECORDS:
      return {
        ...state,
        logDetails: payload,
        editLogLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_SECTION,
  SHOW_SECTION_LOADING,
  SECTION_ERROR,
  SHOW_EDIT_SECTION_LOADING,
  EDIT_SECTION,
  DELETE_SECTION,
  CREATE_SECTION,
  GET_SECTION_DETAILS,
} from '../actions/types';

/**
 * ymploki-197: Build section parts interface (new)
 */

const initialState = {
  sectionlist: [],
  sectionID: null,
  sectionDetails: null,
  sectionLoading: false,
  editsectionLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SECTION_LOADING:
      return {
        ...state,
        sectionLoading: true,
      };
    case SHOW_EDIT_SECTION_LOADING:
      return {
        ...state,
        editsectionLoading: true,
      };
    case GET_SECTION:
      return {
        ...state,
        sectionlist: payload,
        sectionLoading: false,
      };
    case CREATE_SECTION:
      return {
        ...state,
        sectionlist: [payload, ...state.sectionlist],
        editsectionLoading: false,
      };
    case EDIT_SECTION:
      return {
        ...state,
        sectionlist: state.sectionlist.map((section) =>
          section._id === payload._id
            ? {
                ...section,
                sectionName: payload.sectionName,
                equipmentID: payload.equipmentID,
                downloadUrl: payload.downloadUrl,
              }
            : section
        ),
        editsectionLoading: false,
      };
    case GET_SECTION_DETAILS:
      return {
        ...state,
        sectionDetails: payload,
      };
    case DELETE_SECTION:
      return {
        ...state,
        sectionlist: state.sectionlist.filter(
          (section) => section._id !== payload
        ),
        editsectionLoading: false,
        sectionLoading: false,
      };
    case SECTION_ERROR:
      return {
        ...state,
        error: payload,
        sectionLoading: false,
      };
    default:
      return state;
  }
}

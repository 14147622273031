import {
  SHOW_HOURS_LOADING,
  GET_HOURS,
  HOURS_ERROR,
  EDIT_HOURS,
  CREATE_HOURS_SUCCESS,
  DELETE_HOURS,
  GET_PRINT_HOURS,
} from '../actions/types';

/**
 * olain-tarcina-sc-346: Hours of rest print table
 */

const initialState = {
  hourlist: [],
  // Modification start - 03.03.2023 - olain-tarcina-sc-346
  printhourlist: [],
  // Modification end - 03.03.2023 - olain-tarcina-sc-346
  hourLoading: false,
  editHourLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_HOURS_LOADING:
      return {
        ...state,
        hourLoading: true,
      };
    case GET_HOURS:
      return {
        ...state,
        hourlist: payload,
        hourLoading: false,
      };
    case EDIT_HOURS:
      return {
        ...state,
        hourlist: state.hourlist.map((event) =>
          event._id === payload._id
            ? {
                ...event,
                start: payload.start,
                end: payload.end,
              }
            : event
        ),
        editHourLoading: false,
        hourLoading: false,
      };
    case CREATE_HOURS_SUCCESS:
      return {
        ...state,
        hourlist: [payload, ...state.hourlist],
        hourLoading: false,
      };
    // Modification start - 03.03.2023 - olain-tarcina-sc-346
    case GET_PRINT_HOURS:
      return {
        ...state,
        printhourlist: payload,
        hourLoading: false,
      };
    // Modification end - 03.03.2023 - olain-tarcina-sc-346
    case DELETE_HOURS:
      return {
        ...state,
        hourlist: state.hourlist.filter((event) => event._id !== payload),
        hourLoading: false,
      };
    case HOURS_ERROR:
      return {
        ...state,
        error: payload,
        hourLoading: false,
      };

    default:
      return state;
  }
}

import React from 'react';
import { APP_NAME } from 'configs/AppConfig';
import { Link } from 'react-router-dom';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { Row, Col } from 'antd';

/**
 * maco-magsael-sc-407 - Terms and conditions - add terms in webpage footer
 * maco-magsael-sc-432: Footer - Change ‘Copyrightt’ to ‘Copyright’

 */

export default function Footer() {
  return (
    <footer className="footer">
      {/* Modification start - 04.27.2023 - maco-magsael-sc-407*/}
      <Row gutter={[16, 16]}>
        <Col>
          <span>
            {/* Modification start - 06.09.2023 - maco-magsael-sc-432*/}
            Copyright &copy; {`${new Date().getFullYear()}`}{' '}
            {/* Modification end - 06.09.2023 - maco-magsael-sc-432*/}
            <span className="font-weight-semibold">{`${APP_NAME}`}</span> All
            rights reserved.
          </span>
        </Col>
        <Col>
          <span>
            <Link
              style={{ display: 'inline-block' }}
              to={`${APP_PREFIX_PATH}/terms`}
            >
              Terms
            </Link>
          </span>
        </Col>
      </Row>
      {/* Modification end - 04.27.2023 - maco-magsael-sc-407*/}
    </footer>
  );
}

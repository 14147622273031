import {
  SHOW_VACATIONS_LOADING,
  GET_VACATIONS,
  VACATIONS_ERROR,
  EDIT_VACATIONS,
  CREATE_VACATIONS_SUCCESS,
  DELETE_VACATIONS,
} from '../actions/types';

const initialState = {
  vacationlist: [],
  vacationLoading: false,
  editVacationLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_VACATIONS_LOADING:
      return {
        ...state,
        vacationLoading: true,
      };
    case GET_VACATIONS:
      return {
        ...state,
        vacationlist: payload,
        vacationLoading: false,
      };
    case EDIT_VACATIONS:
      return {
        ...state,
        vacationlist: state.vacationlist.map((event) =>
          event._id === payload._id
            ? {
                ...event,
                date: payload.date,
              }
            : event
        ),
        editVacationLoading: false,
        vacationLoading: false,
      };
    case CREATE_VACATIONS_SUCCESS:
      return {
        ...state,
        vacationlist: [payload, ...state.vacationlist],
        vacationLoading: false,
      };
    case VACATIONS_ERROR:
      return {
        ...state,
        error: payload,
        vacationLoading: false,
      };
    case DELETE_VACATIONS:
      return {
        ...state,
        vacationlist: state.vacationlist.filter(
          (event) => event._id !== payload
        ),
        vacationLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_DISCUSSION,
  SHOW_DISCUSSION_LOADING,
  DISCUSSION_ERROR,
  GET_DISCUSSION_DETAILS,
  SHOW_EDIT_DISCUSSION_LOADING,
  EDIT_DISCUSSION,
  DELETE_DISCUSSION,
  CREATE_DISCUSSION,
  COMMENT_LOADING,
  ADD_COMMENT,
  REMOVE_COMMENT,
} from '../actions/types';

/**
 * ympshipyard: Build interface for shipyard (new)
 */

const initialState = {
  discussionlist: [],
  discussionID: null,
  discussionDetails: null,
  discussionLoading: false,
  editdiscussionLoading: false,
  commentLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_DISCUSSION_LOADING:
      return {
        ...state,
        discussionLoading: true,
      };
    case SHOW_EDIT_DISCUSSION_LOADING:
      return {
        ...state,
        editdiscussionLoading: true,
      };
    case COMMENT_LOADING:
      return {
        ...state,
        commentLoading: true,
      };
    case GET_DISCUSSION:
      return {
        ...state,
        discussionlist: payload,
        discussionDetails: null,
        discussionLoading: false,
      };
    case CREATE_DISCUSSION:
      return {
        ...state,
        discussionlist: [payload, ...state.discussionlist],
        discussionDetails: payload,
        editdiscussionLoading: false,
        discussionLoading: false,
      };
    case EDIT_DISCUSSION:
      return {
        ...state,
        discussionlist: state.discussionlist.map((discussion) =>
          discussion._id === payload._id
            ? {
                ...discussion,
                name: payload.name,
              }
            : discussion
        ),
        editdiscussionLoading: false,
      };
    case GET_DISCUSSION_DETAILS:
      return {
        ...state,
        discussionDetails: payload,
        discussionLoading: false,
        commentLoading: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        discussionlist: state.discussionlist.map((discussion) =>
          discussion._id === payload._id
            ? {
                ...discussion,
                comments: payload.comments,
              }
            : discussion
        ),
        discussionDetails: payload,
        commentLoading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        discussionlist: state.discussionlist.map((discussion) =>
          discussion._id === payload._id
            ? {
                ...discussion,
                comments: payload.comments,
              }
            : discussion
        ),
        discussionDetails: payload,
        commentLoading: false,
      };
    case DELETE_DISCUSSION:
      return {
        ...state,
        discussionlist: state.discussionlist.filter(
          (discussion) => discussion._id !== payload
        ),
        editdiscussionLoading: false,
        discussionLoading: false,
        discussionDetails: null,
      };
    case DISCUSSION_ERROR:
      return {
        ...state,
        error: payload,
        discussionLoading: false,
        editdiscussionLoading: false,
      };
    default:
      return state;
  }
}

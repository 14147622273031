import {
  GET_SPAREJOBS,
  SPAREJOBS_ERROR,
  SHOW_SPAREJOBS_LOADING,
  CREATE_SPAREJOBS_SUCCESS,
  DELETE_SPAREJOBS,
} from '../actions/types';

const initialState = {
  sparejoblist: [],
  sparejobID: null,
  sparejobdetails: null,
  spareLoading: false,
  editSpareLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SPAREJOBS_LOADING:
      return {
        ...state,
        spareLoading: true,
      };
    case GET_SPAREJOBS:
      return {
        ...state,
        sparejoblist: payload,
        spareLoading: false,
      };
    case CREATE_SPAREJOBS_SUCCESS:
      return {
        ...state,
        sparejoblist: [payload, ...state.sparejoblist],
        spareLoading: false,
      };
    case DELETE_SPAREJOBS:
      return {
        ...state,
        sparejoblist: state.sparejoblist.filter(
          (sparejob) => sparejob._id !== payload
        ),
        spareLoading: false,
      };
    case SPAREJOBS_ERROR:
      return {
        ...state,
        error: payload,
        spareLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_MERCHANTS,
  MERCHANT_ERROR,
  SHOW_MERCHANT_LOADING,
  SHOW_EDIT_MERCHANT_LOADING,
  GET_MERCHANT_DETAILS,
  CREATE_MERCHANT,
  EDIT_MERCHANT,
  DELETE_MERCHANT,
} from '../actions/types';

/**
 * maco-magsael-sc-606: Suppliers - add manage suppliers
 *
 */

const initialState = {
  merchantlist: [],
  merchantID: null,
  merchantDetails: null,
  merchantLoading: false,
  editMerchantLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MERCHANT_LOADING:
      return {
        ...state,
        merchantLoading: true,
      };
    case SHOW_EDIT_MERCHANT_LOADING:
      return {
        ...state,
        editMerchantLoading: true,
      };
    case GET_MERCHANTS:
      return {
        ...state,
        merchantlist: payload,
        merchantLoading: false,
      };
    case MERCHANT_ERROR:
      return {
        ...state,
        error: payload,
        merchantLoading: false,
      };
    case GET_MERCHANT_DETAILS:
      return {
        ...state,
        merchantDetails: payload,
        merchantLoading: false,
        editMerchantLoading: false,
      };
    case CREATE_MERCHANT:
      return {
        ...state,
        merchantlist: [payload, ...state.merchantlist],
        editMerchantLoading: false,
        merchantLoading: false,
      };
    case EDIT_MERCHANT:
      return {
        ...state,
        merchantDetails: payload,
        // modification start - 02.27.2024 - maco-magsael-sc-606
        merchantlist: state.merchantlist.map((merchant) =>
          merchant._id === payload._id
            ? {
                ...merchant,
                name: payload.name,
              }
            : merchant
        ),
        // modification end - 02.27.2024 - maco-magsael-sc-606
        editMerchantLoading: false,
        merchantLoading: false,
      };
    case DELETE_MERCHANT:
      return {
        ...state,
        merchantlist: state.merchantlist.filter((log) => log._id !== payload),
        merchantLoading: false,
        // modification start - 02.27.2024 - maco-magsael-sc-606
        editMerchantLoading: false,
        // modification end - 02.27.2024 - maco-magsael-sc-606
      };
    default:
      return state;
  }
}

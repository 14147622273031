import {
  GET_BOATFORMS,
  BOATFORMS_ERROR,
  SHOW_BOATFORMS_LOADING,
  CREATE_BOATFORM_SUCCESS,
  MIGRATE_SUCCESS,
  GET_BOATFORM_DETAILS,
  DELETE_BOATFORM,
  EDIT_BOATFORM,
} from '../actions/types';

/** Modifications
 *  maco-magsael-sc-246 - ISM - view form delete button doesn't change after delete
 * maco-magsael-sc-326: ISM - make form creation similar with manage logbook interface
 *
 */

const initialState = {
  boatformlist: [],
  boatformID: null,
  boatformDetails: null,
  boatboatformLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_BOATFORMS_LOADING:
      return {
        ...state,
        boatformLoading: true,
      };
    case GET_BOATFORMS:
      return {
        ...state,
        boatformlist: payload,
        boatformLoading: false,
      };
    case BOATFORMS_ERROR:
      return {
        ...state,
        error: payload,
        boatformLoading: false,
      };
    case CREATE_BOATFORM_SUCCESS:
      return {
        ...state,
        boatformlist: [payload, ...state.boatformlist],
        boatformLoading: false,
      };
    case MIGRATE_SUCCESS:
      return {
        ...state,
        boatformLoading: false,
      };
    case EDIT_BOATFORM:
      // modification start - 2.3.2023 - maco-magsael-sc-326
      return {
        ...state,
        boatformlist: state.boatformlist.map((form) =>
          form._id === payload._id
            ? {
                ...form,
                name: payload.name,
              }
            : form
        ),
        boatformLoading: false,
      };
    // modification end - 2.3.2023 - maco-magsael-sc-326

    case GET_BOATFORM_DETAILS:
      return {
        ...state,
        boatformDetails: payload,
        boatformLoading: false,
      };
    case DELETE_BOATFORM:
      return {
        ...state,
        boatformlist: state.boatformlist.filter((form) => form._id !== payload),
        // modification start - 10.17.2022 - maco-magsael-sc-246
        boatformLoading: false,
        // modification end - 10.17.2022 - maco-magsael-sc-246
      };
    default:
      return state;
  }
}

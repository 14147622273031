import {
  GET_GUESTS,
  GUESTS_ERROR,
  SHOW_GUESTS_LOADING,
  GET_GUEST_DETAILS,
  CREATE_GUESTS_SUCCESS,
  SHOW_EDIT_GUESTS_LOADING,
  GET_EDIT_GUEST_DETAILS,
  DELETE_GUEST,
} from '../actions/types';

const initialState = {
  guestlist: [],
  guestID: null,
  guestdetails: null,
  guestLoading: false,
  editguestloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_GUESTS_LOADING:
      return {
        ...state,
        guestLoading: true,
      };
    case SHOW_EDIT_GUESTS_LOADING:
      return {
        ...state,
        editguestLoading: true,
      };
    case GET_GUESTS:
      return {
        ...state,
        guestlist: payload,
        guestLoading: false,
      };
    case GET_GUEST_DETAILS:
      return {
        ...state,
        guestdetails: payload,
        guestLoading: false,
      };
    case GET_EDIT_GUEST_DETAILS:
      return {
        ...state,
        guestdetails: payload,
        editguestLoading: false,
      };
    case CREATE_GUESTS_SUCCESS:
      return {
        ...state,
        guestlist: [payload, ...state.guestlist],
        guestLoading: false,
      };
    case DELETE_GUEST:
      return {
        ...state,
        guestlist: state.guestlist.filter((guest) => guest._id !== payload),
      };
    case GUESTS_ERROR:
      return {
        ...state,
        error: payload,
        guestLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_INVENTORY,
  SHOW_INVENTORY_LOADING,
  INVENTORY_ERROR,
  GET_INVENTORY_DETAILS,
  SHOW_EDIT_INVENTORY_LOADING,
  CREATE_INVENTORY,
  EDIT_INVENTORY,
  DELETE_INVENTORY,
  SHOW_DELETE_INVENTORY_LOADING,
  GET_MORE_INVENTORY,
  SEARCH_INVENTORY,
  // Modification start - 08.08.2024 - maco-magsael-sc-673
  UPLOAD_INVENTORY_CSV,
  // Modification end - 08.08.2024 - maco-magsael-sc-673
} from '../actions/types';

/** Modifications
 * maco-magsael-sc-381: Inventory - Edit print layout to show Location Name filter and add search box
 * olain-tarcina-sc-634: Add subcategorty to inventory
 * maco-magsael-sc-673: Inventory - Add Parse csv feature
 */

const initialState = {
  inventorylist: [],
  moreinventorylist: [],
  searchinventorylist: [],
  inventoryID: null,
  inventorydetails: null,
  inventoryLoading: false,
  editloading: false,
  deleteLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_INVENTORY_LOADING:
      return {
        ...state,
        inventoryLoading: true,
      };
    case SHOW_EDIT_INVENTORY_LOADING:
      return {
        ...state,
        editloading: true,
      };
    case SHOW_DELETE_INVENTORY_LOADING:
      return {
        ...state,
        deleteLoading: true,
      };
    case GET_INVENTORY:
      return {
        ...state,
        inventorylist: payload,
        inventoryLoading: false,
      };
    case CREATE_INVENTORY:
      return {
        ...state,
        inventorylist: [payload, ...state.inventorylist],
        moreinventorylist: [payload, ...state.inventorylist],
        editloading: false,
      };
    case EDIT_INVENTORY:
      return {
        ...state,
        moreinventorylist: state.inventorylist.map((inventory) =>
          inventory._id === payload._id
            ? {
                ...inventory,
                name: payload.name,
                partNo: payload.partNo,
                totalAmount: payload.totalAmount,
                category: payload.category,
                manufacturer: payload.manufacturer,
                minAmount: payload.minAmount,
                critical: payload.critical,
                private: payload.private,
                locationID: payload.locationID,
                locationBox: payload.locationBox,
                modelNo: payload.modelNo,
                price: payload.price,
                supplier: payload.supplier,
                description: payload.description,
                // Modification start - 25.04.2024 - olain-tarcina-sc-634
                subCategory: payload.subCategory,
                // Modification end - 25.04.2024 - olain-tarcina-sc-634
              }
            : inventory
        ),
        inventorylist: state.inventorylist.map((inventory) =>
          inventory._id === payload._id
            ? {
                ...inventory,
                name: payload.name,
                partNo: payload.partNo,
                totalAmount: payload.totalAmount,
                category: payload.category,
                manufacturer: payload.manufacturer,
                minAmount: payload.minAmount,
                critical: payload.critical,
                private: payload.private,
                locationID: payload.locationID,
                locationBox: payload.locationBox,
                modelNo: payload.modelNo,
                price: payload.price,
                supplier: payload.supplier,
                description: payload.description,
                // modification start - 03.27.2023 - maco-magsael-sc-381
                shelves: payload.shelves,
                // modification end - 03.27.2023 - maco-magsael-sc-381
                // modification start - 04.01.2024 - galing-620
                expDate: payload.expDate,
                // modification end - 04.01.2024 - galing-620
                // Modification start - 25.04.2024 - olain-tarcina-sc-634
                subCategory: payload.subCategory,
                // Modification end - 25.04.2024 - olain-tarcina-sc-634
              }
            : inventory
        ),
        editloading: false,
      };
    case GET_INVENTORY_DETAILS:
      return {
        ...state,
        inventorydetails: payload,
      };
    case DELETE_INVENTORY:
      return {
        ...state,
        inventorylist: state.inventorylist.filter(
          (inventory) => inventory._id !== payload
        ),
        moreinventorylist: state.inventorylist.filter(
          (inventory) => inventory._id !== payload
        ),
        deleteLoading: false,
      };
    // Modification start - 14.02.2023 - olain-tarcina-sc-323
    case GET_MORE_INVENTORY:
      return {
        ...state,
        moreinventorylist: state.moreinventorylist.concat(payload),
        inventoryLoading: false,
      };
    // Modification end - 14.02.2023 - olain-tarcina-sc-323
    case SEARCH_INVENTORY:
      return {
        ...state,
        searchinventorylist: state.searchinventorylist.concat(payload),
        inventoryLoading: false,
      };
    // Modification start - 08.08.2024 - maco-magsael-sc-673
    case UPLOAD_INVENTORY_CSV:
      return {
        ...state,
        inventorylist: [...payload, ...state.inventorylist],
        moreinventorylist: [...payload, ...state.moreinventorylist],
        inventoryLoading: false,
      };
    // Modification end - 08.08.2024 - maco-magsael-sc-673
    case INVENTORY_ERROR:
      return {
        ...state,
        error: payload,
        inventoryLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_PROJECT,
  SHOW_PROJECT_LOADING,
  PROJECT_ERROR,
  GET_PROJECT_DETAILS,
  SHOW_EDIT_PROJECT_LOADING,
  EDIT_PROJECT,
  DELETE_PROJECT,
  CREATE_PROJECT,
} from '../actions/types';

const initialState = {
  projectlist: [],
  projectID: null,
  projectDetails: null,
  projectloading: false,
  editProjectloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PROJECT_LOADING:
      return {
        ...state,
        projectloading: true,
      };
    case SHOW_EDIT_PROJECT_LOADING:
      return {
        ...state,
        editProjectloading: true,
      };
    case GET_PROJECT:
      return {
        ...state,
        projectlist: payload,
        projectloading: false,
      };
    case PROJECT_ERROR:
      return {
        ...state,
        error: payload,
        projectloading: false,
      };
    case CREATE_PROJECT:
      return {
        ...state,
        projectlist: [payload, ...state.projectlist],
        projectloading: false,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        projectlist: state.projectlist.filter((job) => job._id !== payload),
      };
    case EDIT_PROJECT:
      return {
        ...state,
        projectDetails: payload,
        projectloading: false,
      };
    case GET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: payload,
        projectloading: false,
      };
    default:
      return state;
  }
}

import {
  GET_ORDER,
  SHOW_ORDER_LOADING,
  ORDER_ERROR,
  SHOW_EDIT_ORDER_LOADING,
  EDIT_ORDER,
  DELETE_ORDER,
  CREATE_ORDER,
  GET_ORDER_DETAILS,
  GET_ORDERLIST,
} from '../actions/types';

/**
 * ymploki-197: Build Spare parts interface (new)
 * olain-tarcina-sc-639: add order history
 */

const initialState = {
  orderlist: [],
  orderID: null,
  orderDetails: null,
  orderLoading: false,
  editorderLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_ORDER_LOADING:
      return {
        ...state,
        orderLoading: true,
      };
    case SHOW_EDIT_ORDER_LOADING:
      return {
        ...state,
        editorderLoading: true,
      };
    case GET_ORDER:
      return {
        ...state,
        orderlist: payload,
        orderLoading: false,
      };
    case CREATE_ORDER:
      return {
        ...state,
        orderDetails: payload,
        editorderLoading: false,
      };
    case EDIT_ORDER:
      return {
        ...state,
        orderDetails: payload,
        editorderLoading: false,
        orderLoading: false,
      };
    case GET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: payload,
        orderLoading: false,
      };
    // Modification start - 05.05.2024 - olain-tarcina-sc-639
    case GET_ORDERLIST:
      return {
        ...state,
        orderlist: payload,
        orderLoading: false,
      };
    // Modification end - 05.05.2024 - olain-tarcina-sc-639
    case DELETE_ORDER:
      return {
        ...state,
        orderlist: state.orderlist.filter((spare) => spare._id !== payload),
        editorderLoading: false,
        orderLoading: false,
      };
    case ORDER_ERROR:
      return {
        ...state,
        error: payload,
        orderLoading: false,
        editorderLoading: false,
      };
    default:
      return state;
  }
}

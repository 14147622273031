import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from '@novu/notification-center';
import { Row, Col } from 'antd';

export const Notification = ({ auth: { user }, theme: { currentTheme } }) => {
  const theme = currentTheme;
  const userID = user?._id;

  const handleNotificationClick = (message) => {
    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
    }
  };

  const styles = {
    notifications: {
      listItem: {
        layout: {
          '.mantine-Avatar-image': {
            'object-fit': 'none',
          },
        },
      },
    },
  };

  return (
    <Fragment>
      <Row justify="center" align="middle">
        <Col>
          <NovuProvider
            subscriberId={userID}
            applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID}
            styles={styles}
          >
            <PopoverNotificationCenter
              colorScheme={theme}
              onNotificationClick={handleNotificationClick}
            >
              {({ unseenCount }) => (
                <NotificationBell unseenCount={unseenCount} />
              )}
            </PopoverNotificationCenter>
          </NovuProvider>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  theme: state.theme,
});

export default connect(mapStateToProps, null)(Notification);

import {
  GET_YACHTS,
  CREATE_YACHTS,
  YACHTS_LOADING,
  DELETE_YACHT,
  YACHT_ERROR,
} from '../actions/types';

const initialState = {
  yachtlist: [],
  yachtLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case YACHTS_LOADING:
      return {
        ...state,
        yachtLoading: true,
      };
    case GET_YACHTS:
      return {
        ...state,
        yachtlist: payload,
        yachtLoading: false,
      };
    case CREATE_YACHTS:
      return {
        ...state,
        yachtlist: [payload, ...state.yachtlist],
        yachtLoading: false,
      };
    case YACHT_ERROR:
      return {
        ...state,
        error: payload,
        yachtLoading: false,
      };
    case DELETE_YACHT:
      return {
        ...state,
        yachtlist: state.yachtlist.filter((yacht) => yacht._id !== payload),
        yachtLoading: false,
      };

    default:
      return state;
  }
}

import {
  GET_LOGBOOKS,
  SHOW_LOGBOOK_LOADING,
  LOGBOOK_ERROR,
  GET_LOGBOOK_DETAILS,
  CREATE_LOGBOOK_SUCCESS,
  DELETE_LOGBOOK,
} from '../actions/types';

const initialState = {
  logbooklist: [],
  logbookID: null,
  logbookDetails: null,
  logbookLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LOGBOOK_LOADING:
      return {
        ...state,
        logbookLoading: true,
      };
    case GET_LOGBOOKS:
      return {
        ...state,
        logbooklist: payload,
        logbookLoading: false,
      };
    case LOGBOOK_ERROR:
      return {
        ...state,
        error: payload,
        logbookLoading: false,
      };
    case GET_LOGBOOK_DETAILS:
      return {
        ...state,
        logbookDetails: payload,
        logbookLoading: false,
      };
    case CREATE_LOGBOOK_SUCCESS:
      return {
        ...state,
        logbookLoading: false,
      };
    case DELETE_LOGBOOK:
      return {
        ...state,
        logbooklist: state.logbooklist.filter(
          (logbook) => logbook._id !== payload
        ),
        logbookLoading: false,
      };
    default:
      return state;
  }
}

import React, { Fragment, useEffect } from 'react';
import Views from 'views';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTheme } from 'actions/theme';

/**
 * loki-ribeiro-sc-238: save themes for individual users
 */

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const ThemeSwitcher = ({
  theme: { currentTheme },
  auth: { user },
  getTheme,
}) => {
  const userID = user && user._id;

  useEffect(() => {
    getTheme(userID);
  }, [getTheme, userID]);

  return (
    <Fragment>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
    </Fragment>
  );
};

ThemeSwitcher.propTypes = {
  getTheme: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  auth: state.auth,
});

export default connect(mapStateToProps, { getTheme })(ThemeSwitcher);

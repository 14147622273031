import {
  SHOW_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS,
  SEND_NOTIFICATIONS,
  NOTIFICATION_ERROR,
} from '../actions/types';

const initialState = {
  notifications: [],
  notificationLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationLoading: true,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
        notificationLoading: false,
      };
    case SEND_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
        notificationLoading: false,
      };
    case NOTIFICATION_ERROR:
      return {
        ...state,
        error: payload,
        notificationLoading: false,
      };
    default:
      return state;
  }
}

import {
  SHOW_WATCHKEEPLOGS_LOADING,
  GET_WATCHKEEPLOGS,
  WATCHKEEPLOGS_ERROR,
} from '../actions/types';

const initialState = {
  watchkeeploglist: [],
  watchkeeplogLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_WATCHKEEPLOGS_LOADING:
      return {
        ...state,
        watchkeeplogLoading: true,
      };
    case GET_WATCHKEEPLOGS:
      return {
        ...state,
        watchkeeploglist: payload,
        watchkeeplogLoading: false,
      };
    case WATCHKEEPLOGS_ERROR:
      return {
        ...state,
        error: payload,
        watchkeeplogLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_SHIPYARDDOC,
  SHIPYARDDOC_ERROR,
  SHOW_SHIPYARDDOC_LOADING,
  SHOW_EDIT_SHIPYARDDOC_LOADING,
  GET_SHIPYARDDOC_DETAILS,
  CREATE_SHIPYARDDOC,
  EDIT_SHIPYARDDOC,
  DELETE_SHIPYARDDOC,
} from '../actions/types';

const initialState = {
  shipyarddoclist: [],
  shipyarddocID: null,
  shipyarddocDetails: null,
  shipyarddocLoading: false,
  editShipyarddocLoading: false,
  error: {},
};

/**
 * new-shipyard created
 *
 */

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SHIPYARDDOC_LOADING:
      return {
        ...state,
        shipyarddocLoading: true,
      };
    case SHOW_EDIT_SHIPYARDDOC_LOADING:
      return {
        ...state,
        editShipyarddocLoading: true,
      };
    case GET_SHIPYARDDOC:
      return {
        ...state,
        shipyarddoclist: payload,
        shipyarddocLoading: false,
      };
    case SHIPYARDDOC_ERROR:
      return {
        ...state,
        error: payload,
        shipyarddocLoading: false,
        editShipyarddocLoading: false,
      };
    case GET_SHIPYARDDOC_DETAILS:
      return {
        ...state,
        shipyarddocDetails: payload,
        shipyarddocLoading: false,
        editShipyarddocLoading: false,
      };
    case CREATE_SHIPYARDDOC:
      return {
        ...state,
        shipyarddoclist: [...payload, ...state.shipyarddoclist],
        editShipyarddocLoading: false,
        shipyarddocLoading: false,
      };
    case EDIT_SHIPYARDDOC:
      return {
        ...state,
        shipyarddoclist: state.shipyarddoclist.map((shipyarddoc) =>
          shipyarddoc._id === payload._id
            ? {
                ...shipyarddoc,
              }
            : shipyarddoc
        ),
        editShipyarddocLoading: false,
        shipyarddocLoading: false,
      };
    case DELETE_SHIPYARDDOC:
      return {
        ...state,
        shipyarddoclist: state.shipyarddoclist.filter(
          (shipyarddoc) => shipyarddoc._id !== payload
        ),
        editShipyarddocLoading: false,
        shipyarddocLoading: false,
      };
    default:
      return state;
  }
}

import { combineReducers } from 'redux';

/**
 * maco-magsael-sc-551: Accounting - Create Invoice generator
 * new-shipyard
 * maco-magsael-sc-663: Shipyard - add files and pinpoint feature
 *
 */

import auth from './auth';
import alert from './alert';
import theme from './theme';
import worklist from './worklist';
import group from './group';
import doc from './doc';
import history from './history';
import logbook from './logbook';
import subgroup from './subgroup';
import inventory from './inventory';
import profile from './profile';
import user from './user';
import guests from './guests';
import photo from './photo';
import boat from './boat';
import ympequipment from './ympequipment';
import ympjob from './ympjob';
import form from './form';
import subform from './subform';
import boatform from './boatform';
import location from './location';
import sparejob from './sparejob';
import taskgroup from './taskgroup';
import event from './event';
import watchkeeper from './watchkeeper';
import watchkeeplog from './watchkeeplog';
import hourevent from './hourevent';
import vacation from './vacation';
import boatsubform from './boatsubform';
import formarchive from './formarchive';
// modification start - 08.31.2022 - ympshipyard
import team from './team';
import discussion from './discussion';
import project from './project';
import deck from './deck';
import room from './room';
import projecthistory from './projecthistory';
// modification end - 08.31.2022 - ympshipyard
// modificationm start - 09.28.2022 - ymploki-197
import spare from './spare';
import equipment from './equipment';
import sectionlist from './sectionlist';
import orderlist from './orderlist';
import supplier from './supplier';
// modification end - 09.28.2022 - ymploki-197
// modificationm start - 10.31.2022 - ymploki-277
import log from './log';
import logrecord from './logrecord';
// modificationm end - 10.31.2022 - ymploki-277
// modificationm start - 12.16.2022 - ymploki-277
import expense from './expense';
import merchant from './merchant';
import category from './category';
// modificationm end - 12.16.2022 - ymploki-277
// modification start - 02.06.2023 - great-galing-sc-325
import subcategory from './subcategory';
// modification end - 02.06.2023 - great-galing-sc-325
/** Modifications
 * great-galing-sc-325: Add department to all fields
 */
// Modification start - 03.07.2023 - olain-tarcina-sc-451
import sales from './sales';
import yacht from './yacht';
// Modification end - 12.07.2023 - olain-tarcina-sc-451
// modification start - 08.04.2023 - maco-magsael-sc-458
import calendar from './calendar';
// modification end - 08.04.2023 - maco-magsael-sc-458
// Modification start - 07.30.2023 - management-module
import management from './management';
// Modification end - 07.30.2023 - management-module
import yachtmake from './yachtmake';
// Modification start - 09.20.2023 - maco-magsael-sc-485
import part from './part';
// Modification end - 09.20.2023 - maco-magsael-sc-485
// modification start - 12.07.2023 - maco-magsael-sc-551
import invoice from './invoice';
// modification end - 12.07.2023 - maco-magsael-sc-551
// Modification start - 25.09.2023 - olain-tarcina-sc-479
import notification from './notification';
// Modification end - 25.09.2023 - olain-tarcina-sc-479
// Modification start - 04.24.2024 - new-shipyard
import shipyard from './shipyard';
import shipyardhistory from './shipyardhistory';
import shipyarddoc from './shipyarddoc';
// Modification end - 04.24.2024 - new-shipyard
// Modification start - 07.18.2024 - nmaco-magsael-sc-663
import plan from './plan';
// Modification end - 07.18.2024 - nmaco-magsael-sc-663

const reducers = combineReducers({
  auth,
  theme,
  alert,
  worklist,
  group,
  doc,
  history,
  logbook,
  subgroup,
  inventory,
  profile,
  user,
  guests,
  photo,
  boat,
  ympequipment,
  ympjob,
  form,
  subform,
  boatform,
  location,
  sparejob,
  taskgroup,
  event,
  watchkeeper,
  watchkeeplog,
  hourevent,
  vacation,
  boatsubform,
  formarchive,
  // modification start - 08.31.2022 - ympshipyard
  team,
  discussion,
  project,
  deck,
  room,
  projecthistory,
  // modification end - 08.31.2022 - ympshipyard
  // modificationm start - 09.28.2022 - ymploki-197
  spare,
  equipment,
  sectionlist,
  orderlist,
  supplier,
  // modification end - 09.28.2022 - ymploki-197
  // modificationm start - 10.31.2022 - ymploki-277
  log,
  logrecord,
  // modificationm end - 10.31.2022 - ymploki-277
  // modificationm start - 12.16.2022 - ymploki-277
  expense,
  merchant,
  category,
  // modificationm end - 12.16.2022 - ymploki-277
  // modification start - 02.06.2023 - great-galing-sc-325
  subcategory,
  // modification end - 02.06.2023 - great-galing-sc-325
  sales,
  yacht,
  // modification start - 08.04.2023 - maco-magsael-sc-458
  calendar,
  // modification end - 08.04.2023 - maco-magsael-sc-458
  // Modification start - 07.30.2023 - management-module
  management,
  // Modification end - 07.30.2023 - management-module
  yachtmake,
  // Modification start - 09.20.2023 - maco-magsael-sc-485
  part,
  // Modification end - 09.20.2023 - maco-magsael-sc-485
  // modification start - 12.07.2023 - maco-magsael-sc-551
  invoice,
  // modification end - 12.07.2023 - maco-magsael-sc-551
  // Modification start - 25.09.2023 - olain-tarcina-sc-479
  notification,
  // Modification end - 25.09.2023 - olain-tarcina-sc-479
  // Modification start - 04.24.2024 - new-shipyard
  shipyard,
  shipyardhistory,
  shipyarddoc,
  // Modification end - 04.24.2024 - new-shipyard
  // Modification start - 07.18.2024 - nmaco-magsael-sc-663
  plan,
  // Modification end - 07.18.2024 - nmaco-magsael-sc-663
});

export default reducers;

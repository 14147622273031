import {
  GET_FORMARCHIVES,
  SHOW_FORMARCHIVE_LOADING,
  FORMARCHIVE_ERROR,
  GET_FORMARCHIVE_DETAILS,
  CREATE_FORMARCHIVE_SUCCESS,
  DELETE_FORMARCHIVE,
  EDIT_FORMARCHIVE,
  // modification start - 03.07.2023 - maco-magsael-sc-345
  SHOW_EDIT_FORMARCHIVE_LOADING,
  // modification end - 03.07.2023 - maco-magsael-sc-345
} from '../actions/types';

/**
 * maco-magsael-sc-345: ISM - autosave implementation
 */

const initialState = {
  formarchivelist: [],
  formarchiveID: null,
  formarchiveDetails: null,
  formarchiveLoading: false,
  editFormarchiveLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_FORMARCHIVE_LOADING:
      return {
        ...state,
        formarchiveLoading: true,
      };
    // modification start - 03.07.2023 - maco-magsael-sc-345
    case SHOW_EDIT_FORMARCHIVE_LOADING:
      return {
        ...state,
        editFormarchiveLoading: true,
      };
    // modification end - 03.07.2023 - maco-magsael-sc-345
    case GET_FORMARCHIVES:
      return {
        ...state,
        formarchivelist: payload,
        formarchiveLoading: false,
      };
    case FORMARCHIVE_ERROR:
      return {
        ...state,
        error: payload,
        formarchiveLoading: false,
      };
    case GET_FORMARCHIVE_DETAILS:
      return {
        ...state,
        formarchiveDetails: payload,
        formarchiveLoading: false,
        // modification start - 03.07.2023 - maco-magsael-sc-345
        editFormarchiveLoading: false,
        // modification end - 03.07.2023 - maco-magsael-sc-345
      };
    case CREATE_FORMARCHIVE_SUCCESS:
      return {
        ...state,
        // modification start - 02.23.2023 - maco-magsael-sc-345
        formarchivelist: [payload, ...state.formarchivelist],
        formarchiveLoading: false,
        editFormarchiveLoading: false,
      };

    case EDIT_FORMARCHIVE:
      return {
        ...state,
        formarchiveDetails: payload,
        formarchiveLoading: false,
        editFormarchiveLoading: false,
      };
    // modification end - 02.23.2023 - maco-magsael-sc-345
    case DELETE_FORMARCHIVE:
      return {
        ...state,
        formarchivelist: state.formarchivelist.filter(
          (form) => form._id !== payload
        ),
        formarchiveLoading: false,
        // modification start - 03.07.2023 - maco-magsael-sc-345
        editFormarchiveLoading: false,
        // modification end - 03.07.2023 - maco-magsael-sc-345
      };
    default:
      return state;
  }
}

/** Modifications
 * great-galing-sc-325: Add department to all fields
 */
import {
  GET_SUBCATEGORIES,
  SUBCATEGORY_ERROR,
  SHOW_SUBCATEGORY_LOADING,
  SHOW_EDIT_SUBCATEGORY_LOADING,
  GET_SUBCATEGORY_DETAILS,
  CREATE_SUBCATEGORY,
  EDIT_SUBCATEGORY,
  DELETE_SUBCATEGORY,
} from '../actions/types';

const initialState = {
  subcategorylist: [],
  subcategoryID: null,
  subcategoryDetails: null,
  subcategoryLoading: false,
  editSubcategoryLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SUBCATEGORY_LOADING:
      return {
        ...state,
        subcategoryLoading: true,
      };
    case SHOW_EDIT_SUBCATEGORY_LOADING:
      return {
        ...state,
        editSubcategoryLoading: true,
      };
    case GET_SUBCATEGORIES:
      return {
        ...state,
        subcategorylist: payload,
        subcategoryLoading: false,
      };
    case SUBCATEGORY_ERROR:
      return {
        ...state,
        error: payload,
        subcategoryLoading: false,
      };
    case GET_SUBCATEGORY_DETAILS:
      return {
        ...state,
        subcategoryDetails: payload,
        subcategoryLoading: false,
        editSubcategoryLoading: false,
      };
    case CREATE_SUBCATEGORY:
      return {
        ...state,
        subcategorylist: [payload, ...state.subcategorylist],
        editSubcategoryLoading: false,
        subcategoryLoading: false,
      };
    case EDIT_SUBCATEGORY:
      return {
        ...state,
        subcategoryDetails: payload,
        editSubcategoryLoading: false,
        subcategoryLoading: false,
      };
    case DELETE_SUBCATEGORY:
      return {
        ...state,
        subcategorylist: state.subcategorylist.filter(
          (log) => log._id !== payload
        ),
        subcategoryLoading: false,
      };
    default:
      return state;
  }
}

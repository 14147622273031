import React, { useEffect } from 'react';
import { Menu, Dropdown, Avatar, message } from 'antd';
import { connect } from 'react-redux';
import {
  EditOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  HourglassOutlined,
  BulbOutlined,
  CarOutlined,
  // Modification start - 09.20.2023 - maco-magsael-sc-485
  PictureOutlined,
  // Modification end - 09.20.2023 - maco-magsael-sc-485
  // CalendarOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { logout } from '../../actions/auth';
import { getProfileDetailsByUserID } from '../../actions/profile';
import PropTypes from 'prop-types';

/**
 * ymploki-157: Add expired state for boats
 * ympshipyard: Build interface for shipyard
 * ympolain-177: Shipyard user profile
 * olain-tarcina-sc-442: remove watchkeeper and vacation leave links
 * olain-tarcina-sc-468: Management - Management Revisions
 * maco-magsael-sc-485: Equipment - Add annotation for equipment
 * maco-magsael-sc-499: Profile: Why is Hours of Rest missing for some accounts
 * maco-magsael-sc-643: Shipyard: New shipyard modifications
 *
 */

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: `${APP_PREFIX_PATH}/editprofile`,
    key: 'editprofile',
  },

  {
    title: 'Hours of Rest',
    icon: HourglassOutlined,
    path: `${APP_PREFIX_PATH}/hoursofrest`,
    key: 'hoursofrest',
  },
  {
    title: 'On / Off Board',
    icon: BulbOutlined,
    path: `${APP_PREFIX_PATH}/onboard`,
    key: 'onboard',
  },
  {
    title: 'My Vessel',
    icon: CarOutlined,
    path: `${APP_PREFIX_PATH}/myvessel`,
    key: 'myvessel',
  },
  // Modification start - 09.20.2023 - maco-magsael-sc-485

  // Modification end - 09.20.2023 - maco-magsael-sc-485
  // Modification start - 29.06.2023 - olain-tarcina-sc-442
  // {
  //   title: 'Vacation Leaves',
  //   icon: CalendarOutlined,
  //   path: `${APP_PREFIX_PATH}/vacationleaves`,
  //   key: 'vacationleaves',
  // },
  // Modification end - 29.06.2023 - olain-tarcina-sc-442
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: `${APP_PREFIX_PATH}/help`,
    key: 'help',
  },
];

export const NavProfile = ({
  auth: { user, loading },
  logout,
  isMobile,
  // modification start - 08.20.2022 - ymploki-157
  boat: { boatdetails },
  // modification end - 08.20.2022 - ymploki-157
  profile: { profileDetails },
  getProfileDetailsByUserID,
}) => {
  // Modification start - 30.11.2023 - olain-tarcina-sc-522
  useEffect(() => {
    const userID = localStorage.getItem('userID');
    getProfileDetailsByUserID(userID);

    // eslint-disable-next-line
  }, []);
  // Modification end - 30.11.2023 - olain-tarcina-sc-522

  //const profileImg = '/img/avatars/thumb-1.jpg';
  // const res = getProfileUserID(user?._id);
  // console.log(res);

  const profileImg = user && user.profilePhoto;
  const profileName = profileDetails?.firstName;
  const jobTitle = user && user.jobtitle;
  const userID = user && user._id;

  // modification start - 08.20.2022 - ymploki-157
  const free = boatdetails && boatdetails.free;
  const superadmin = user && user.superadmin;
  // modification end - 08.20.2022 - ymploki-157

  // modificastion start - 09.05.2022 - ympshipyard
  // const shipyard = user && user.shipyard;
  // * Modification start - 08.15.2023 - olain-tarcina-sc-468
  const role = user && user.role;
  // * Modification end - 08.15.2023 - olain-tarcina-sc-468

  const management = user?.management;
  const changePassword = user?.changePassword;

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{profileName}</h4>
            {/* // * Modification start - 08.15.2023 - olain-tarcina-sc-468 */}
            <span className="text-muted">
              {role === 'management' ? 'Management' : jobTitle}
            </span>
            {/* // * Modification end - 08.15.2023 - olain-tarcina-sc-468 */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* modification start - 08.20.2022 - ymploki-157 */}
          {/* // modification start - 05.24.2024 - maco-magsael-sc-643 */}
          {(free !== 'EXPIRED' || superadmin) &&
          !(user?.shipyardUser || user?.contractUser)
            ? // modification end - 05.24.2024 - maco-magsael-sc-643
              menuItem.map((el, i) =>
                el.title === 'Edit Profile' ? (
                  changePassword ? null : (
                    <Menu.Item key={i}>
                      <a href={el.path + '/' + userID}>
                        <Icon className="mr-3" type={el.icon} />
                        <span className="font-weight-normal">{el.title}</span>
                      </a>
                    </Menu.Item>
                  )
                ) : el.title === 'Hours of Rest' &&
                  management === true ? null : changePassword ? null : (
                  <Menu.Item key={i}>
                    <a href={el.path}>
                      <Icon className="mr-3" type={el.icon} />
                      <span className="font-weight-normal">{el.title}</span>
                    </a>
                  </Menu.Item>
                )
              )
            : null}
          {/* modification start - 08.20.2022 - ymploki-157 */}

          {/* Modification start - 16.09.2922 - ympolain-177 */}
          {/* // modification start - 05.24.2024 - maco-magsael-sc-643 */}
          {user?.shipyardUser || user?.contractUser ? (
            // modification end - 05.24.2024 - maco-magsael-sc-643
            <Menu.Item key={1}>
              <a href={`${APP_PREFIX_PATH}/shipyarprofile/${userID}`}>
                <Icon className="mr-3" type={menuItem[0].icon} />
                <span className="font-weight-normal">Edit Profile</span>
              </a>
            </Menu.Item>
          ) : null}
          {/* Modification end - 16.09.2922 - ympolain-177 */}
          {/* // Modification start - 10.04.2023 - maco-magsael-sc-499 */}
          {superadmin === undefined ? null : changePassword ? null : (
            <Menu.Item key={menuItem.length + 1}>
              <a href={`${APP_PREFIX_PATH}/equipmentphotos`}>
                <Icon className="mr-3" type={PictureOutlined} />
                <span className="font-weight-normal">Equipment Photos</span>
              </a>
            </Menu.Item>
          )}
          {/* // Modification end - 10.04.2023 - maco-magsael-sc-499 */}
          <Menu.Item key={menuItem.length + 2} onClick={(e) => handleLogout()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  // Modification start - 30.11.2023 - olain-tarcina-sc-550
  const handleLogout = () => {
    message.destroy();
    logout();
  };
  // Modification end - 30.11.2023 - olain-tarcina-sc-550

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
          {!isMobile ? (
            <span className="text-primary">
              {' '}
              {profileName ? 'Hi, ' + profileName : null}
            </span>
          ) : null}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

NavProfile.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  // modification start - 08.20.2022 - ymploki-157
  boat: PropTypes.object.isRequired,
  // modification end - 08.20.2022 - ymploki-157
  profile: PropTypes.object.isRequired,
  getProfileDetailsByUserID: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  // modification start - 08.20.2022 - ymploki-157
  boat: state.boat,
  // modification end - 08.20.2022 - ymploki-157
  profile: state.profile,
});

export default connect(mapStateToProps, { logout, getProfileDetailsByUserID })(
  NavProfile
);

import {
  GET_SALES,
  GET_CLIENTS,
  GET_SALES_DETAILS,
  CREATE_SALES,
  EDIT_SALES,
  DELETE_CLIENT,
  DELETE_SALES,
  SHOW_SALES_LOADING,
  SALES_ERROR,
} from '../actions/types';

/** Modifications
 *  olain-tarcina-sc-543: client details feature
 *
 */

const initialState = {
  saleslist: [],
  clients: [],
  salesDetails: null,
  salesLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SALES_LOADING:
      return {
        ...state,
        salesLoading: true,
      };
    case GET_SALES:
      return {
        ...state,
        saleslist: payload,
        salesLoading: false,
      };
    // Modification start - 16.11.2023 - olain-tarcina-sc-543
    case GET_CLIENTS:
      return {
        ...state,
        clients: payload,
        salesLoading: false,
      };
    // Modification end - 16.11.2023 - olain-tarcina-sc-543
    case GET_SALES_DETAILS:
      return {
        ...state,
        salesDetails: payload,
        salesLoading: false,
      };
    case SALES_ERROR:
      return {
        ...state,
        error: payload,
        salesLoading: false,
      };
    case CREATE_SALES:
      return {
        ...state,
        saleslist: [payload, ...state.saleslist],
        salesLoading: false,
      };
    case EDIT_SALES:
      return {
        ...state,
        salesDetails: payload,
        salesLoading: false,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        salesDetails: payload,
        salesLoading: false,
      };
    case DELETE_SALES:
      return {
        ...state,
        saleslist: state.sales.filter((sale) => sale._id !== payload),
        salesLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_EXPENSES,
  EXPENSE_ERROR,
  SHOW_EXPENSE_LOADING,
  SHOW_EDIT_EXPENSE_LOADING,
  GET_EXPENSE_DETAILS,
  CREATE_EXPENSE,
  EDIT_EXPENSE,
  DELETE_EXPENSE,
  DUPLICATE_EXPENSE,
} from '../actions/types';

/** Modifications
 * great-galing-sc-325: Add department to all fields
 * great-galing-sc-379: optimizations (Add a checkbox called reimbursement)
 * maco-magsael-sc-558: Accounting - Category / subcategory
 */

const initialState = {
  expenseslist: [],
  expenseID: null,
  expenseDetails: null,
  expenseLoading: false,
  editExpenseLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_EXPENSE_LOADING:
      return {
        ...state,
        expenseLoading: true,
      };
    case SHOW_EDIT_EXPENSE_LOADING:
      return {
        ...state,
        editExpenseLoading: true,
      };
    case GET_EXPENSES:
      return {
        ...state,
        expenseslist: payload,
        expenseLoading: false,
      };
    case EXPENSE_ERROR:
      return {
        ...state,
        error: payload,
        expenseLoading: false,
        editExpenseLoading: false,
      };
    case GET_EXPENSE_DETAILS:
      return {
        ...state,
        expenseDetails: payload,
        expenseLoading: false,
        editExpenseLoading: false,
      };
    case CREATE_EXPENSE:
      return {
        ...state,
        expenseslist: [payload, ...state.expenseslist],
        editExpenseLoading: false,
        expenseLoading: false,
      };
    case EDIT_EXPENSE:
      return {
        ...state,
        expenseslist: state.expenseslist.map((expense) =>
          expense._id === payload._id
            ? {
                ...expense,
                merchant: payload.merchant,
                date: payload.date,
                total: payload.total,
                category: payload.category,
                // modification start - 02.08.2023 - great-galing-sc-325
                subcategory: payload.subcategory,
                // modification end - 02.08.2023 - great-galing-sc-325
                // modification start - 01.10.2024 - maco-magsael-sc-558
                newSubcategory: payload.newSubcategory,
                // modification end - 01.10.2024 - maco-magsael-sc-558
                description: payload.description,
                attachments: payload.attachments,
                // modification start - 03.21.2023 - great-galing-sc-379
                reimbursement: payload.reimbursement,
                // modification end - 03.21.2023 - great-galing-sc-379
              }
            : expense
        ),
        editExpenseLoading: false,
        expenseLoading: false,
      };
    case DELETE_EXPENSE:
      return {
        ...state,
        expenseslist: state.expenseslist.filter(
          (expense) => expense._id !== payload
        ),
        editExpenseLoading: false,
        expenseLoading: false,
      };
    case DUPLICATE_EXPENSE:
      return {
        ...state,
        expenseslist: payload,
        editExpenseLoading: false,
        expenseLoading: false,
      };

    default:
      return state;
  }
}

import {
  SHOW_MANAGEMENT_LOADING,
  GET_MANAGEMENT_DETAILS,
  MANAGEMENT_ERROR,
  GET_MANAGEMENTS,
  SHOW_EDIT_MANAGEMENT_LOADING,
  DELETE_MANAGEMENT,
  CREATE_MANAGEMENT,
  EDIT_MANAGEMENT,
} from '../actions/types';

const initialState = {
  managementlist: [],
  managementDetails: null,
  managementLoading: false,
  editManagementLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MANAGEMENT_LOADING:
      return {
        ...state,
        managementLoading: true,
      };
    case SHOW_EDIT_MANAGEMENT_LOADING:
      return {
        ...state,
        editManagementLoading: true,
      };
    case GET_MANAGEMENTS:
      return {
        ...state,
        managementlist: payload,
        managementLoading: false,
      };
    case CREATE_MANAGEMENT:
      return {
        ...state,
        managementlist: [payload, ...state.managementlist],
        editManagementLoading: false,
      };
    case EDIT_MANAGEMENT:
      return {
        ...state,
        managementlist: state.managementlist.map((manage) =>
          manage._id === payload._id
            ? {
                ...manage,
                name: payload.name,
                address: payload.address,
                contact: payload.contact,
                free: payload.free,
              }
            : manage
        ),
        managementDetails: payload,
        editManagementLoading: false,
      };
    case GET_MANAGEMENT_DETAILS:
      return {
        ...state,
        managementDetails: payload,
        managementLoading: false,
      };
    case DELETE_MANAGEMENT:
      return {
        ...state,
        managementlist: state.managementlist.filter(
          (manage) => manage._id !== payload
        ),
        editManagementLoading: false,
        managementLoading: false,
      };
    case MANAGEMENT_ERROR:
      return {
        ...state,
        error: payload,
        managementLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_YMPJOBS,
  YMPJOBS_ERROR,
  SHOW_YMPJOBS_LOADING,
  CREATE_YMPJOBS_SUCCESS,
  CREATE_YMPJOBS_FAIL,
  GET_YMPJOBDETAILS,
  SHOW_EDIT_YMPJOB_LOADING,
  DELETE_YMPJOB,
} from '../actions/types';

const initialState = {
  ympjoblist: [],
  ympjobID: null,
  ympjobDetails: null,
  ympjobLoading: false,
  editYmpjobLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_YMPJOBS_LOADING:
      return {
        ...state,
        ympjobLoading: true,
      };
    case SHOW_EDIT_YMPJOB_LOADING:
      return {
        ...state,
        editYmpjobLoading: true,
      };
    case GET_YMPJOBS:
      return {
        ...state,
        ympjoblist: payload,
        ympjobLoading: false,
      };
    case YMPJOBS_ERROR:
      return {
        ...state,
        error: payload,
        ympjobLoading: false,
      };
    case CREATE_YMPJOBS_SUCCESS:
      return {
        ...state,
        ympjoblist: [payload, ...state.ympjoblist],
        ympjobLoading: false,
      };
    case CREATE_YMPJOBS_FAIL:
      return {
        ...state,
        error: payload,
        ympjobLoading: false,
      };
    case GET_YMPJOBDETAILS:
      return {
        ...state,
        ympjobDetails: payload,
        ympjobLoading: false,
      };
    case DELETE_YMPJOB:
      return {
        ...state,
        ympjoblist: state.ympjoblist.filter((job) => job._id !== payload),
      };
    default:
      return state;
  }
}

import {
  GET_BOATSUBFORMS,
  BOATSUBFORMS_ERROR,
  SHOW_BOATSUBFORMS_LOADING,
  CREATE_BOATSUBFORM_SUCCESS,
  EDIT_BOATSUBFORM,
  DELETE_BOATSUBFORM,
  DELETE_MANY_BOATSUBFORMS,
} from '../actions/types';

const initialState = {
  boatsubformlist: [],
  boatsubformLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_BOATSUBFORMS_LOADING:
      return {
        ...state,
        boatsubformLoading: true,
      };
    case GET_BOATSUBFORMS:
      return {
        ...state,
        boatsubformlist: payload,
        boatsubformLoading: false,
      };
    case BOATSUBFORMS_ERROR:
      return {
        ...state,
        error: payload,
        boatsubformLoading: false,
      };
    case CREATE_BOATSUBFORM_SUCCESS:
      return {
        ...state,
        boatsubformlist: [...state.boatsubformlist, payload],
        boatsubformLoading: false,
      };
    case EDIT_BOATSUBFORM:
      return {
        ...state,
        boatsubformlist: state.boatsubformlist.map((boatsubform) =>
          boatsubform._id === payload._id
            ? {
                ...boatsubform,
                subname: payload.subname,
                itemType: payload.itemType,
                options: payload.options,
                status: payload.status,
                formID: payload.formID,
                order: payload.order,
              }
            : boatsubform
        ),
        boatsubformLoading: false,
      };
    case DELETE_BOATSUBFORM:
      return {
        ...state,
        boatsubformlist: state.boatsubformlist.filter(
          (boatsubform) => boatsubform._id !== payload
        ),
        boatsubformLoading: false,
      };
    case DELETE_MANY_BOATSUBFORMS:
      return {
        ...state,
        boatsubformlist: payload.map((id) =>
          state.boatsubformlist.filter((boatsubform) => boatsubform._id !== id)
        ),
        boatsubformLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_SUBFORMS,
  SUBFORMS_ERROR,
  SHOW_SUBFORMS_LOADING,
  CREATE_SUBFORM_SUCCESS,
  CREATE_SUBFORM_FAIL,
  GET_SUBFORM_DETAILS,
  EDIT_SUBFORM,
  SHOW_EDIT_SUBFORMS_LOADING,
  DELETE_SUBFORM,
  DELETE_MANY_SUBFORMS,
} from '../actions/types';

const initialState = {
  subformlist: [],
  subformID: null,
  subformDetails: null,
  subformLoading: false,
  editSubformLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SUBFORMS_LOADING:
      return {
        ...state,
        subformLoading: true,
      };
    case SHOW_EDIT_SUBFORMS_LOADING:
      return {
        ...state,
        editSubformLoading: true,
      };
    case GET_SUBFORMS:
      return {
        ...state,
        subformlist: payload,
        subformLoading: false,
      };
    case CREATE_SUBFORM_FAIL:
    case SUBFORMS_ERROR:
      return {
        ...state,
        error: payload,
        subformLoading: false,
      };
    case CREATE_SUBFORM_SUCCESS:
      return {
        ...state,
        subformlist: [...state.subformlist, payload],
        subformLoading: false,
      };
    case GET_SUBFORM_DETAILS:
      return {
        ...state,
        subformDetails: payload,
        subformLoading: false,
      };
    case EDIT_SUBFORM:
      return {
        ...state,
        subformlist: state.subformlist.map((subform) =>
          subform._id === payload._id
            ? {
                ...subform,
                subname: payload.subname,
                itemType: payload.itemType,
                options: payload.options,
                status: payload.status,
                formID: payload.formID,
                order: payload.order,
              }
            : subform
        ),
        subformLoading: false,
      };
    case DELETE_SUBFORM:
      return {
        ...state,
        subformlist: state.subformlist.filter(
          (subform) => subform._id !== payload
        ),
        subformLoading: false,
      };
    case DELETE_MANY_SUBFORMS:
      return {
        ...state,
        subformlist: payload.map((id) =>
          state.subformlist.filter((subform) => subform._id !== id)
        ),
        subformLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_DETAILS_PROJECTHISTORY,
  PROJECTHISTORY_ERROR,
  SHOW_PROJECTHISTORY_LOADING,
  PROJECT_DONE,
} from '../actions/types';

/**
 * ympshipyard: Build interface for shipyard (new)
 */

const initialState = {
  projhistorylist: [],
  historyID: null,
  historydetails: null,
  projhistoryLoading: false,
  groupprojhistoryLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PROJECTHISTORY_LOADING:
      return {
        ...state,
        projhistoryLoading: true,
      };
    case GET_DETAILS_PROJECTHISTORY:
      return {
        ...state,
        projhistorylist: payload,
        projhistoryLoading: false,
      };
    case PROJECT_DONE:
      return {
        ...state,
        projhistorylist: [...state.projhistorylist],
        projhistoryLoading: false,
      };
    case PROJECTHISTORY_ERROR:
      return {
        ...state,
        error: payload,
        projhistoryLoading: false,
      };
    default:
      return state;
  }
}

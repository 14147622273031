import {
  GET_WORKLISTS,
  SHOW_WORKLIST_LOADING,
  WORKLIST_ERROR,
  SHOW_EDIT_WORKLIST_LOADING,
  CREATE_WORKLIST_SUCCESS,
  CREATE_WORKLIST_FAIL,
  GET_JOBDETAILS,
  DELETE_WORKLIST,
  // modification start - 05.24.2024 - maco-magsael-sc-643
  GET_SHIPYARDJOBS,
  SHOW_SHIPYARDJOB_LOADING,
  CREATE_SHIPYARDJOB,
  // modification end - 05.24.2024 - maco-magsael-sc-643
  // modification start - 07.02.2024 - maco-magsael-sc-655
  GET_WORKLIST_TASKS,
  // modification end - 07.02.2024 - maco-magsael-sc-655
} from '../actions/types';

/**
 * maco-magsael-sc-643: Shipyard: New shipyard modifications
 * maco-magsael-sc-655: Jobs: In Jobdetails when you create Checklist the data you fill up in the Submit Job deleted
 *
 */

const initialState = {
  joblist: [],
  // modification start - 05.24.2024 - maco-magsael-sc-643
  shipyardlist: [],
  shipyardLoading: false,
  // modification end - 05.24.2024 - maco-magsael-sc-643
  worklistID: null,
  worklistDetails: null,
  // modification start - 07.02.2024 - maco-magsael-sc-655
  worklistTasks: null,
  // modification end - 07.02.2024 - maco-magsael-sc-655
  loading: false,
  editloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_WORKLIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SHOW_EDIT_WORKLIST_LOADING:
      return {
        ...state,
        editloading: true,
      };
    // modification start - 05.24.2024 - maco-magsael-sc-643
    case SHOW_SHIPYARDJOB_LOADING:
      return {
        ...state,
        shipyardLoading: true,
      };
    // modification end - 05.24.2024 - maco-magsael-sc-643
    case GET_WORKLISTS:
      return {
        ...state,
        joblist: payload,
        loading: false,
      };
    // modification start - 05.24.2024 - maco-magsael-sc-643
    case GET_SHIPYARDJOBS:
      return {
        ...state,
        shipyardlist: payload,
        shipyardLoading: false,
      };
    // modification end - 05.24.2024 - maco-magsael-sc-643
    case WORKLIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CREATE_WORKLIST_SUCCESS:
      return {
        ...state,
        joblist: [payload, ...state.joblist],
        loading: false,
      };
    // modification start - 05.24.2024 - maco-magsael-sc-643
    case CREATE_SHIPYARDJOB:
      return {
        ...state,
        shipyardlist: [payload, ...state.shipyardlist],
        shipyardLoading: false,
      };
    // modification end - 05.24.2024 - maco-magsael-sc-643
    case DELETE_WORKLIST:
      return {
        ...state,
        joblist: state.joblist.filter((job) => job._id !== payload),
      };
    case CREATE_WORKLIST_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_JOBDETAILS:
      return {
        ...state,
        worklistDetails: payload,
        // modification start - 07.02.2024 - maco-magsael-sc-655
        worklistTasks: {
          jobTasks: payload.jobTasks,
        },
        loading: false,
      };

    case GET_WORKLIST_TASKS:
      return {
        ...state,
        worklistTasks: {
          jobTasks: payload.jobTasks,
        },
        // modification end - 07.02.2024 - maco-magsael-sc-655
        loading: false,
      };
    default:
      return state;
  }
}

import {
  GET_TASKGROUPS,
  TASKGROUPS_ERROR,
  SHOW_TASKGROUPS_LOADING,
  SHOW_EDIT_TASKGROUPS_LOADING,
  GET_TASKGROUP_DETAILS,
  DELETE_TASK,
  CREATE_TASKGROUP_SUCCESS,
  EDIT_TASKGROUP,
  DELETE_TASKGROUP,
} from '../actions/types';

const initialState = {
  taskgrouplist: [],
  taskgroupID: null,
  taskgroupDetails: null,
  taskLoading: false,
  editTaskLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_TASKGROUPS_LOADING:
      return {
        ...state,
        taskLoading: true,
      };
    case SHOW_EDIT_TASKGROUPS_LOADING:
      return {
        ...state,
        editTaskLoading: true,
      };
    case GET_TASKGROUPS:
      return {
        ...state,
        taskgrouplist: payload,
        taskLoading: false,
      };
    case TASKGROUPS_ERROR:
      return {
        ...state,
        error: payload,
        taskLoading: false,
        editTaskLoading: false,
      };
    case EDIT_TASKGROUP:
      return {
        ...state,
        taskgrouplist: state.taskgrouplist.map((taskgroup) =>
          taskgroup._id === payload._id
            ? {
                ...taskgroup,
                name: payload.name,
              }
            : taskgroup
        ),
        editTaskLoading: false,
        taskLoading: false,
      };
    case GET_TASKGROUP_DETAILS:
      return {
        ...state,
        taskgroupDetails: payload,
        taskLoading: false,
        editTaskLoading: false,
      };
    case CREATE_TASKGROUP_SUCCESS:
      return {
        ...state,
        taskgrouplist: [payload, ...state.taskgrouplist],
        taskLoading: false,
      };
    case DELETE_TASK:
      return {
        ...state,
        taskgroupDetails: {
          ...state.taskgroupDetails,
          taskgrouplist: state.taskgroupDetails.taskgrouplist.filter(
            (task) => task._id !== payload
          ),
        },
        editTaskLoading: false,
        taskLoading: false,
      };
    case DELETE_TASKGROUP:
      return {
        ...state,
        taskgrouplist: state.taskgrouplist.filter(
          (taskgroup) => taskgroup._id !== payload
        ),
        taskLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_SHIPYARDHISTORY,
  SHIPYARDHISTORY_ERROR,
  SHOW_SHIPYARDHISTORY_LOADING,
  SHOW_EDIT_SHIPYARDHISTORY_LOADING,
  GET_SHIPYARDHISTORY_DETAILS,
  CREATE_SHIPYARDHISTORY,
  EDIT_SHIPYARDHISTORY,
  DELETE_SHIPYARDHISTORY,
} from '../actions/types';

const initialState = {
  shipyardhistorylist: [],
  shipyardhistoryID: null,
  shipyardhistoryDetails: null,
  shipyardhistoryLoading: false,
  editShipyardhistoryLoading: false,
  error: {},
};

/**
 * new-shipyard created
 *
 */

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SHIPYARDHISTORY_LOADING:
      return {
        ...state,
        shipyardhistoryLoading: true,
      };
    case SHOW_EDIT_SHIPYARDHISTORY_LOADING:
      return {
        ...state,
        editShipyardhistoryLoading: true,
      };
    case GET_SHIPYARDHISTORY:
      return {
        ...state,
        shipyardhistorylist: payload,
        shipyardhistoryLoading: false,
      };
    case SHIPYARDHISTORY_ERROR:
      return {
        ...state,
        error: payload,
        shipyardhistoryLoading: false,
        editShipyardhistoryLoading: false,
      };
    case GET_SHIPYARDHISTORY_DETAILS:
      return {
        ...state,
        shipyardhistoryDetails: payload,
        shipyardhistoryLoading: false,
        editShipyardhistoryLoading: false,
      };
    case CREATE_SHIPYARDHISTORY:
      return {
        ...state,
        shipyardhistorylist: [payload, ...state.shipyardhistorylist],
        editShipyardhistoryLoading: false,
        shipyardhistoryLoading: false,
      };
    case EDIT_SHIPYARDHISTORY:
      return {
        ...state,
        shipyardhistorylist: state.shipyardhistorylist.map((shipyardhistory) =>
          shipyardhistory._id === payload._id
            ? {
                ...shipyardhistory,
              }
            : shipyardhistory
        ),
        editShipyardhistoryLoading: false,
        shipyardhistoryLoading: false,
      };
    case DELETE_SHIPYARDHISTORY:
      return {
        ...state,
        shipyardhistorylist: state.shipyardhistorylist.filter(
          (shipyardhistory) => shipyardhistory._id !== payload
        ),
        editShipyardhistoryLoading: false,
        shipyardhistoryLoading: false,
      };
    default:
      return state;
  }
}

import {
  GET_TEAM,
  SHOW_TEAM_LOADING,
  TEAM_ERROR,
  GET_TEAM_DETAILS,
  SHOW_EDIT_TEAM_LOADING,
  EDIT_TEAM,
  DELETE_TEAM,
  CREATE_TEAM,
} from '../actions/types';

/**
 * ympshipyard: Build interface for shipyard (new)
 */

const initialState = {
  teamlist: [],
  teamID: null,
  teamDetails: null,
  teamLoading: false,
  editTeamLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_TEAM_LOADING:
      return {
        ...state,
        teamLoading: true,
      };
    case SHOW_EDIT_TEAM_LOADING:
      return {
        ...state,
        editTeamLoading: true,
      };
    case GET_TEAM:
      return {
        ...state,
        teamlist: payload,
        teamLoading: false,
      };
    case CREATE_TEAM:
      return {
        ...state,
        teamlist: [payload, ...state.teamlist],
        editTeamLoading: false,
      };
    case EDIT_TEAM:
      return {
        ...state,
        teamlist: state.teamlist.map((team) =>
          team._id === payload._id
            ? {
                ...team,
                name: payload.name,
              }
            : team
        ),
        editTeamLoading: false,
      };
    case GET_TEAM_DETAILS:
      return {
        ...state,
        teamDetails: payload,
      };
    case DELETE_TEAM:
      return {
        ...state,
        teamlist: state.teamlist.filter((team) => team._id !== payload),
        editTeamLoading: false,
      };
    case TEAM_ERROR:
      return {
        ...state,
        error: payload,
        teamLoading: false,
      };
    default:
      return state;
  }
}

import {
  SHOW_USER_LOADING,
  GET_USER_DETAILS,
  USER_ERROR,
  GET_USERS,
  // modification start - 05.24.2024 - maco-magsael-sc-643
  DELETE_USER,
  // modification end - 05.24.2024 - maco-magsael-sc-643
} from '../actions/types';

/**
 * maco-magsael-sc-643: Shipyard: New shipyard modifications
 *
 */

const initialState = {
  userlist: [],
  userID: null,
  userdetails: null,
  userLoading: false,
  edituser: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_USER_LOADING:
      return {
        ...state,
        userLoading: true,
      };
    case GET_USERS:
      return {
        ...state,
        userlist: payload,
        userLoading: false,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userdetails: payload,
        userLoading: false,
      };
    case USER_ERROR:
      return {
        ...state,
        error: payload,
        userLoading: false,
      };
    // modification start - 05.24.2024 - maco-magsael-sc-643
    case DELETE_USER:
      return {
        ...state,
        userlist: state.userlist.filter((user) => user._id !== payload),
        userLoading: false,
      };
    // modification end - 05.24.2024 - maco-magsael-sc-643
    default:
      return state;
  }
}

import {
  SHOW_BOAT_LOADING,
  GET_BOATS,
  BOAT_ERROR,
  GET_BOAT_DETAILS,
  SHOW_EDIT_BOAT_LOADING,
  DELETE_BOAT,
} from '../actions/types';

const initialState = {
  boatlist: [],
  boatID: null,
  boatdetails: null,
  boatLoading: false,
  editBoatLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_BOAT_LOADING:
      return {
        ...state,
        boatLoading: true,
      };
    case SHOW_EDIT_BOAT_LOADING:
      return {
        ...state,
        editBoatLoading: true,
      };
    case GET_BOATS:
      return {
        ...state,
        boatlist: payload,
        boatLoading: false,
      };
    case GET_BOAT_DETAILS:
      return {
        ...state,
        boatdetails: payload,
        boatLoading: false,
        editBoatLoading: false,
      };
    case BOAT_ERROR:
      return {
        ...state,
        error: payload,
        boatLoading: false,
        editBoatLoading: false,
      };
    case DELETE_BOAT:
      return {
        ...state,
        boatlist: state.boatlist.filter((boat) => boat._id !== payload),
        boatLoading: false,
        editBoatLoading: false,
      };
    default:
      return state;
  }
}

import axios from 'axios';
import { GET_THEME_DETAILS, THEME_ERROR } from './types';
import {
  TOGGLE_COLLAPSED_NAV,
  SIDE_NAV_STYLE_CHANGE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  SWITCH_THEME,
  // modification start - 04.05.2023 - maco-magsael-sc-389
  FONT_SIZE,
  // modification end - 04.05.2023 - maco-magsael-sc-389
  // modification start - 16.08.2023 - great-galing-sc-470
  RESET_THEME,
  // modification end - 16.08.2023 - great-galing-sc-470
} from '../constants/Theme';
import { setNotification } from 'components/shared-components/notification/Notification';

/**
 * loki-ribeiro-sc-238: save themes for individual users
 * loki-ribeiro-sc-241: Issues with side nav (delete)
 * maco-magsael-sc-389: Font Size Project - add font size to theme settings
 * great-galing-sc-470: Add reset button
 */

// Get theme details
export const getTheme = (userID) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/themes/themedetails/${userID}`);

    dispatch({
      type: GET_THEME_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: THEME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Edit current theme
export const updateCurrentTheme =
  (currentTheme, userID, themeID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      currentTheme,
      userID,
    });

    try {
      const res = await axios.post(
        `/api/themes/currentTheme/${themeID}`,
        body,
        config
      );

      dispatch({
        type: SWITCH_THEME,
        payload: res.data,
      });

      setNotification('success', 'Theme successfully changed');
    } catch (err) {
      console.info('error', err);
      dispatch({
        type: THEME_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Updating theme failed');
    }
  };

// Edit navType
export const updateNavType = (navType, userID, themeID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    navType,
    userID,
  });

  try {
    const res = await axios.post(
      `/api/themes/navType/${themeID}`,
      body,
      config
    );

    dispatch({
      type: NAV_TYPE_CHANGE,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: THEME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Updating theme failed');
  }
};

// Edit sideNavTheme
export const updateSideNavTheme =
  (sideNavTheme, userID, themeID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      sideNavTheme,
      userID,
    });

    try {
      const res = await axios.post(
        `/api/themes/sideNavTheme/${themeID}`,
        body,
        config
      );

      dispatch({
        type: SIDE_NAV_STYLE_CHANGE,
        payload: res.data,
      });
    } catch (err) {
      console.info('error', err);
      dispatch({
        type: THEME_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Updating theme failed');
    }
  };

// Edit navCollapsed
export const updateNavCollapsed =
  (navCollapsed, userID, themeID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      navCollapsed,
      userID,
    });

    try {
      const res = await axios.post(
        `/api/themes/navCollapsed/${themeID}`,
        body,
        config
      );

      dispatch({
        type: TOGGLE_COLLAPSED_NAV,
        payload: res.data,
      });
    } catch (err) {
      console.info('error', err);
      dispatch({
        type: THEME_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Updating theme failed');
    }
  };

// Edit topNavColor
export const updateTopNavColor =
  (topNavColor, userID, themeID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      topNavColor,
      userID,
    });

    try {
      const res = await axios.post(
        `/api/themes/topNavColor/${themeID}`,
        body,
        config
      );

      dispatch({
        type: TOP_NAV_COLOR_CHANGE,
        payload: res.data,
      });
    } catch (err) {
      console.info('error', err);
      dispatch({
        type: THEME_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Updating theme failed');
    }
  };

// modification start - 04.05.2023 - maco-magsael-sc-389
// Edit fontSize
export const updatefontSize =
  (fontSize, userID, themeID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      fontSize,
      userID,
    });
    console.info('action-font', fontSize);

    try {
      const res = await axios.post(
        `/api/themes/fontSize/${themeID}`,
        body,
        config
      );

      dispatch({
        type: FONT_SIZE,
        payload: res.data,
      });
    } catch (err) {
      console.info('error', err);
      dispatch({
        type: THEME_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Updating theme failed');
    }
  };
// modification end - 04.05.2023 - maco-magsael-sc-389

// modification start - 16.08.2023 - great-galing-sc-470
export const updateResetTheme = (userID, themeID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    userID,
  });

  try {
    const res = await axios.post(
      `/api/themes/resetTheme/${themeID}`,
      body,
      config
    );

    dispatch({
      type: RESET_THEME,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: THEME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Updating theme failed');
  }
};
// modification end - 16.08.2023 - great-galing-sc-470
